<template>
  <div class="mt100 pt50">
    <!-- section begin -->
    <PricingTable></PricingTable> 
    <compare></compare>
  </div>
</template>

<script>
// import $ from "jquery";

import WOW from "wowjs";
import PricingTable from "@/components/siteActions/PricingTable.vue";
import compare from "@/components/pricing/compare.vue";
export default {
  name: "Home",
  data() {
    return {
      checked: false,
    };
  },
  components: {
    PricingTable,
    compare,
  },
  methods: {
    functionName: function () {},
  },
  created: function () {},
  mounted() {
    new WOW.WOW().init();
    this.$parent.custom_bg();
  },
};
</script>
