<template>
  <div>
    <!-- section begin -->
    <section
      id="section-pricing"
      class="no-top"
      data-bgimage="url(/Theme/images/background/3c.png) top"
    >
      <div class="container">
        <div class="row mb-4">
          <div class="col-md-8 offset-md-2">
            <div class="col text-center">
              <h2 v-if="$t('lang') == 'en'">Compare P2C Plans</h2>
              <h2 v-if="$t('lang') == 'ar'">مقارنة الباقات</h2>
            </div>
          </div>
        </div>

        <div class="row mb-4" v-if="$t('lang') == 'en'">
          <div class="col-md-12">
            <div id="accordion-1" class="accordion accordion-style-1">
              <!-- Accordion item 1 -->
              <div class="card">
                <div
                  id="heading-a1"
                  class="card-header bg-white shadow-sm border-0"
                  style="background-size: cover"
                >
                  <h6 class="mb-0 font-weight-bold">
                    <a
                      href="#"
                      data-toggle="collapse"
                      data-target="#collapse-a1"
                      aria-expanded="true"
                      aria-controls="collapse-a1"
                      class="d-block position-relative text-dark collapsible-link py-2"
                    >
                      Essentials</a
                    >
                  </h6>
                </div>
                <div
                  id="collapse-a1"
                  aria-labelledby="heading-a1"
                  class="collapse show"
                  style="background-size: cover"
                >
                  <div class="pt-1" style="background-size: cover">
                    <div
                      class="table-responsive table-Compare"
                      style="background-size: cover"
                    >
                      <table
                        class="table table-striped table-hover table-fixed mb-4"
                        style="font-size: 14px"
                      >
                        <thead>
                          <tr>
                            <th style="width: 30%">PLANS DETAILS</th>
                            <th>Premium</th>
                            <th>Business</th>
                            <th>Business Pro</th>
                            <th>Enterprise</th>
                            <th>Corporate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style="width: 30%">
                              <span>Number of Users</span>
                            </td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                            <td>Unlimited</td>
                          </tr>
                          <tr>
                            <td>File space</td>
                            <td>2 GB</td>
                            <td>5 GB</td>
                            <td>10 GB</td>
                            <td>150 GB</td>
                            <td>150 TB</td>
                          </tr>
                          <tr>
                            <td>Multi-Language</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Two-factors Authentication</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Google authentication</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Single Sign On</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Roles/Permissions</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>User Profile</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Company Profile</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Organizational Chart</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Dynamic Workflow</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Approvals</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Task Notifications</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Unlimited Messaging</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Audit log</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Unlimited Activity log</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Embedded forms</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Backups</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>SSL Security</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Setup Cost</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>iOS and Android Apps</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Private Domain</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Advanced Integrations</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Daily Backups</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>E-Mail &amp; SMS integration</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Progress view</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Data export PDF &amp; Excel</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Support via Email &amp; Live Chat</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Accordion item 2 -->
              <div class="card">
                <div
                  id="heading-a2"
                  class="card-header bg-white shadow-sm border-0"
                >
                  <h6 class="mb-0 font-weight-bold">
                    <a
                      href="#"
                      data-toggle="collapse"
                      data-target="#collapse-a2"
                      aria-expanded="false"
                      aria-controls="collapse-a2"
                      class="d-block position-relative collapsed text-dark collapsible-link py-2"
                      >Tasks Management</a
                    >
                  </h6>
                </div>
                <div
                  id="collapse-a2"
                  aria-labelledby="heading-a2"
                  class="collapse"
                >
                  <div class="pt-1">
                    <div class="table-responsive table-Compare">
                      <table
                        class="table table-striped table-hover table-fixed mb-4"
                        style="font-size: 14px"
                      >
                        <thead>
                          <tr>
                            <th style="width: 30%">PLANS DETAILS</th>
                            <th>Premium</th>
                            <th>Business</th>
                            <th>Business Pro</th>
                            <th>Enterprise</th>
                            <th>Corporate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>To-Do</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Unlimited Task boards</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Timeline view</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Calendar view</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Timesheet view</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Tasks Time Tracking</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Tasks Approval Workflow</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Tasks Evaluation</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Unlimited advice within team</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Unlimited comments & Chat</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Task transferred</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Task Reports</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Task’s dashboard & KPIs</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Kanban Tasks Drag-Drop</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Integrated Team Voice/Video Call</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Accordion item 3 -->
              <div class="card">
                <div
                  id="heading-a3"
                  class="card-header bg-white shadow-sm border-0"
                >
                  <h6 class="mb-0 font-weight-bold">
                    <a
                      href="#"
                      data-toggle="collapse"
                      data-target="#collapse-a3"
                      aria-expanded="false"
                      aria-controls="collapse-a3"
                      class="d-block position-relative collapsed text-dark collapsible-link py-2"
                      >Strategic & Strategy Management</a
                    >
                  </h6>
                </div>
                <div
                  id="collapse-a3"
                  aria-labelledby="heading-a3"
                  class="collapse"
                >
                  <div class="pt-1">
                    <div class="table-responsive table-Compare">
                      <table
                        class="table table-striped table-hover table-fixed mb-4"
                        style="font-size: 14px"
                      >
                        <thead>
                          <tr>
                            <th style="width: 30%">PLANS DETAILS</th>
                            <th>Premium</th>
                            <th>Business</th>
                            <th>Business Pro</th>
                            <th>Enterprise</th>
                            <th>Corporate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Unlimited Strategic Plan</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Strategic Map</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Balanced Scorecard</td>
                            <td></td>

                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Strategy Management</td>
                            <td></td>

                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Unlimited Initiative</td>
                            <td></td>

                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Manual KPIs</td>
                            <td></td>

                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>KPIs Integrations</td>
                            <td></td>

                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Advance search</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Live Dashboard</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Accordion item 1 -->
              <div class="card">
                <div
                  id="heading-b1"
                  class="card-header bg-white shadow-sm border-0"
                >
                  <h6 class="mb-0 font-weight-bold">
                    <a
                      href="#"
                      data-toggle="collapse"
                      data-target="#collapse-b1"
                      aria-expanded="false"
                      aria-controls="collapse-b1"
                      class="d-block position-relative text-dark collapsible-link py-2"
                    >
                      Portfolio & Project Management</a
                    >
                  </h6>
                </div>
                <div
                  id="collapse-b1"
                  aria-labelledby="heading-b1"
                  class="collapse"
                >
                  <div class="pt-1">
                    <div class="table-responsive table-Compare">
                      <table
                        class="table table-striped table-hover table-fixed mb-4"
                        style="font-size: 14px"
                      >
                        <thead>
                          <tr>
                            <th style="width: 30%">PLANS DETAILS</th>
                            <th>Premium</th>
                            <th>Business</th>
                            <th>Business Pro</th>
                            <th>Enterprise</th>
                            <th>Corporate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Portfolio, Program, & Project</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Project Planning</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Project Task Integrations</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Project Documents Management</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Cost Management</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Scheduling</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Project Management Forms</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Engineering Forms</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Status Report</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Risk Management</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Stakeholders Management</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Live Dashboard & KPIs</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Project Management Standards and Forms</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Accordion item 2 -->
              <div class="card">
                <div
                  id="heading-b2"
                  class="card-header bg-white shadow-sm border-0"
                >
                  <h6 class="mb-0 font-weight-bold">
                    <a
                      href="#"
                      data-toggle="collapse"
                      data-target="#collapse-b2"
                      aria-expanded="false"
                      aria-controls="collapse-b2"
                      class="d-block position-relative collapsed text-dark collapsible-link py-2"
                    >
                      Advanced Contacts Directory
                    </a>
                  </h6>
                </div>
                <div
                  id="collapse-b2"
                  aria-labelledby="heading-b2"
                  class="collapse"
                >
                  <div class="pt-1">
                    <div class="table-responsive table-Compare">
                      <table
                        class="table table-striped table-hover table-fixed mb-4"
                        style="font-size: 14px"
                      >
                        <thead>
                          <tr>
                            <th style="width: 30%">PLANS DETAILS</th>
                            <th>Premium</th>
                            <th>Business</th>
                            <th>Business Pro</th>
                            <th>Enterprise</th>
                            <th>Corporate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Unlimited External Contact</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Unlimited Internal Contact</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Unlimited Messaging</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Advance search</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Dashboard & Reports</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  id="heading-b2"
                  class="card-header bg-white shadow-sm border-0"
                >
                  <h6 class="mb-0 font-weight-bold">
                    <a
                      href="#"
                      data-toggle="collapse"
                      data-target="#collapse-b3"
                      aria-expanded="false"
                      aria-controls="collapse-b3"
                      class="d-block position-relative collapsed text-dark collapsible-link py-2"
                      >Committees & Meeting</a
                    >
                  </h6>
                </div>
                <div id="collapse-b3" class="collapse">
                  <div class="pt-1">
                    <div class="table-responsive table-Compare">
                      <table
                        class="table table-striped table-hover table-fixed mb-4"
                        style="font-size: 14px"
                      >
                        <thead>
                          <tr>
                            <th style="width: 30%">PLANS DETAILS</th>
                            <th>Premium</th>
                            <th>Business</th>
                            <th>Business Pro</th>
                            <th>Enterprise</th>
                            <th>Corporate</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Unlimited Boards</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Unlimited Committees</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Unlimited Meeting</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Advance search</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Meeting Task Integrations</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Minutes of meeting</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>Dashboard & Reports</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-4" v-if="$t('lang') == 'ar'">
          <div class="col-md-12">
            <div id="accordion-1" class="accordion accordion-style-1">
              <!-- Accordion item 1 -->
              <div class="card">
                <div
                  id="heading-a1"
                  class="card-header bg-white shadow-sm border-0"
                  style="background-size: cover"
                >
                  <h6 class="mb-0 font-weight-bold">
                    <a
                      href="#"
                      data-toggle="collapse"
                      data-target="#collapse-a1"
                      aria-expanded="true"
                      aria-controls="collapse-a1"
                      class="d-block position-relative text-dark collapsible-link py-2"
                    >
                      الأساسية</a
                    >
                  </h6>
                </div>
                <div
                  id="collapse-a1"
                  aria-labelledby="heading-a1"
                  class="collapse show"
                  style="background-size: cover"
                >
                  <div class="pt-1" style="background-size: cover">
                    <div
                      class="table-responsive table-Compare"
                      style="background-size: cover"
                    >
                      <table
                        class="table table-striped table-hover table-fixed mb-4"
                        style="font-size: 14px"
                      >
                        <thead>
                          <tr>
                            <th style="width: 30%">تفاصيل الباقة</th>
                            <th>التميز</th>
                            <th>أعمال</th>
                            <th>أعمال برو</th>
                            <th>المؤسسية</th>
                            <th>المنظمة</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style="width: 30%">
                              <span>عدد المستخدمين (حسب الطلب)</span>
                            </td>
                            <td>غير محدود</td>
                            <td>غير محدود</td>
                            <td>غير محدود</td>
                            <td>غير محدود</td>
                            <td>غير محدود</td>
                          </tr>
                          <tr>
                            <td>مساحة الملفات</td>
                            <td>2 GB</td>
                            <td>5 GB</td>
                            <td>10 GB</td>
                            <td>150 GB</td>
                            <td>150 TB</td>
                          </tr>
                          <tr>
                            <td>تعدد اللغات</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>مصادقة الدخول الثنائية</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>مصادقة الدخول بحساب جوجل</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>الدخول الموحد (SSO)</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>الصلاحيات المتعددة</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>ملف المستخدم</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>ملف المنظمة</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>الهيكل التنظيمي</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>نظام تدفق العمل (Workflow)</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>نظام الموافقات</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>الاشعارات والتنبيهات</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>عدد لا محدود من الرسائل</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>سجل الحركات</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>سجل أنشطة غير محدود</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>نماذج الكترونية</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>نسخ احتياطي</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تشفير نقل البيانات (SSL)</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تكاليف التركيب والتخصيص</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تطبيقات الجوال (iOS &amp; Android)</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>دومين خاص</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تكامل متقدم مع النظم الأخرى</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>نسخ احتياطي يومي</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تكامل مع رسائل الإيميل والجوال</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>عرض نسب التقدم</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تصدير البيانات (PDF &amp; Excel)</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>دعم فني عبر الإيميل والمحادثة الفورية</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Accordion item 2 -->
              <div class="card">
                <div
                  id="heading-a2"
                  class="card-header bg-white shadow-sm border-0"
                >
                  <h6 class="mb-0 font-weight-bold">
                    <a
                      href="#"
                      data-toggle="collapse"
                      data-target="#collapse-a2"
                      aria-expanded="false"
                      aria-controls="collapse-a2"
                      class="d-block position-relative collapsed text-dark collapsible-link py-2"
                      >إدارة المهام</a
                    >
                  </h6>
                </div>
                <div
                  id="collapse-a2"
                  aria-labelledby="heading-a2"
                  class="collapse"
                >
                  <div class="pt-1">
                    <div class="table-responsive table-Compare">
                      <table
                        class="table table-striped table-hover table-fixed mb-4"
                        style="font-size: 14px"
                      >
                        <thead>
                          <tr>
                            <th style="width: 30%">تفاصيل الباقة</th>
                            <th>التميز</th>
                            <th>أعمال</th>
                            <th>أعمال برو</th>
                            <th>المؤسسية</th>
                            <th>المنظمة</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>مهام شخصية</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>عدد لامحدود من لوحات المهام</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>عرض الخط الزمني</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>عرض بالتقويم</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>عرض سجل الوقت (Timesheet)</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تعقب المهام</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>نظام تسلسل الموافقات</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تقييم المهام</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>عدد لا محدود من توجيهات المهام</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>عدد لا محدود من التعليقات والمحادثات</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تحويل المهام</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تقرير المهام</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>احصائيات ومؤشرات المهام</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>لوحة مهام (سحب وافلات)</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تكامل مع أنظمة محادثة الصوت والفديو</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Accordion item 3 -->
              <div class="card">
                <div
                  id="heading-a3"
                  class="card-header bg-white shadow-sm border-0"
                >
                  <h6 class="mb-0 font-weight-bold">
                    <a
                      href="#"
                      data-toggle="collapse"
                      data-target="#collapse-a3"
                      aria-expanded="false"
                      aria-controls="collapse-a3"
                      class="d-block position-relative collapsed text-dark collapsible-link py-2"
                      >إدارة الاستراتيجية</a
                    >
                  </h6>
                </div>
                <div
                  id="collapse-a3"
                  aria-labelledby="heading-a3"
                  class="collapse"
                >
                  <div class="pt-1">
                    <div class="table-responsive table-Compare">
                      <table
                        class="table table-striped table-hover table-fixed mb-4"
                        style="font-size: 14px"
                      >
                        <thead>
                          <tr>
                            <th style="width: 30%">تفاصيل الباقة</th>
                            <th>التميز</th>
                            <th>أعمال</th>
                            <th>أعمال برو</th>
                            <th>المؤسسية</th>
                            <th>المنظمة</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>عدد لا محدود من الخطط الاستراتيجية</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>الخارطة الاستراتيجية</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>بطاقات الأداء المتوازن</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>إدارة الاستراتيجية</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>عدد لا محدود من المبادرات</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>مؤشرات أداء يدوية</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>مؤشرات أداء آلية مباشرة (تكامل)</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>بحث متقدم</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>لوحة احصائيات آنية (مباشرة)</td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Accordion item 1 -->
              <div class="card">
                <div
                  id="heading-b1"
                  class="card-header bg-white shadow-sm border-0"
                >
                  <h6 class="mb-0 font-weight-bold">
                    <a
                      href="#"
                      data-toggle="collapse"
                      data-target="#collapse-b1"
                      aria-expanded="false"
                      aria-controls="collapse-b1"
                      class="d-block position-relative text-dark collapsible-link py-2"
                    >
                      إدارة البرامج والمشاريع</a
                    >
                  </h6>
                </div>
                <div
                  id="collapse-b1"
                  aria-labelledby="heading-b1"
                  class="collapse"
                >
                  <div class="pt-1">
                    <div class="table-responsive table-Compare">
                      <table
                        class="table table-striped table-hover table-fixed mb-4"
                        style="font-size: 14px"
                      >
                        <thead>
                          <tr>
                            <th style="width: 30%">تفاصيل الباقة</th>
                            <th>التميز</th>
                            <th>أعمال</th>
                            <th>أعمال برو</th>
                            <th>المؤسسية</th>
                            <th>المنظمة</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>عدد لا محدود من المحافظ، والبرامج والمشاريع</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تخطيط المشروع</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تكامل مهام المشروع مع نظام المهام</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>إدارة وثائق المشروع</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>إدارة التكاليف</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>الجدول الزمني</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>نماذج إدارة المشاريع</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>النماذج الهندسية</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تقارير الحالة</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>إدارة المخاطر</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>إدارة المعنيين بالمشاريع</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>لوحة احصائيات آنية (مباشرة)</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>معايير إدارة المشاريع والنماذج</td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Accordion item 2 -->
              <div class="card">
                <div
                  id="heading-b2"
                  class="card-header bg-white shadow-sm border-0"
                >
                  <h6 class="mb-0 font-weight-bold">
                    <a
                      href="#"
                      data-toggle="collapse"
                      data-target="#collapse-b2"
                      aria-expanded="false"
                      aria-controls="collapse-b2"
                      class="d-block position-relative collapsed text-dark collapsible-link py-2"
                    >
                      دليل الاتصالات المتقدم
                    </a>
                  </h6>
                </div>
                <div
                  id="collapse-b2"
                  aria-labelledby="heading-b2"
                  class="collapse"
                >
                  <div class="pt-1">
                    <div class="table-responsive table-Compare">
                      <table
                        class="table table-striped table-hover table-fixed mb-4"
                        style="font-size: 14px"
                      >
                        <thead>
                           <tr>
                            <th style="width: 30%">تفاصيل الباقة</th>
                            <th>التميز</th>
                            <th>أعمال</th>
                            <th>أعمال برو</th>
                            <th>المؤسسية</th>
                            <th>المنظمة</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>عدد لا محدود من العناوين الخارجية</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>عدد لا محدود من العناوين الداخلية</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>عدد لا محدود من الرسائل</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>بحث متقدم</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>لوحة احصائيات</td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div
                  id="heading-b2"
                  class="card-header bg-white shadow-sm border-0"
                >
                  <h6 class="mb-0 font-weight-bold">
                    <a
                      href="#"
                      data-toggle="collapse"
                      data-target="#collapse-b3"
                      aria-expanded="false"
                      aria-controls="collapse-b3"
                      class="d-block position-relative collapsed text-dark collapsible-link py-2"
                      >اللجان والمجالس والاجتماعات</a
                    >
                  </h6>
                </div>
                <div id="collapse-b3" class="collapse">
                  <div class="pt-1">
                    <div class="table-responsive table-Compare">
                      <table
                        class="table table-striped table-hover table-fixed mb-4"
                        style="font-size: 14px"
                      >
                        <thead>
                          <tr>
                            <th style="width: 30%">تفاصيل الباقة</th>
                            <th>التميز</th>
                            <th>أعمال</th>
                            <th>أعمال برو</th>
                            <th>المؤسسية</th>
                            <th>المنظمة</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>عدد لا محدود من المجالس</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>عدد لا محدود من اللجان</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>عدد لا محدود من الاجتماعات</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>بحث متقدم</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>تكامل مهام الاجتماعات مع نظام المهام</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>محاضر الاجتماعات</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                          <tr>
                            <td>لوحة الاحصائيات والمؤشرات</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><i class="fa fa-check"></i></td>
                            <td><i class="fa fa-check"></i></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import $ from "jquery";

import WOW from "wowjs";

export default {
  name: "Home",
  data() {
    return {
      checked: false,
      usersNumber: 5,
      PremiumCost: 37,
      BusinessCost: 57,
      BusinessProCost: 75,
      monthOryear: 1,
      monthOryearText: "Month",
    };
  },
  components: {},
  methods: {
    switchClick: function () {
      if (this.checked == true) {
        this.monthOryear = 1;
        this.monthOryearText = "Month";
        this.checked = false;
        console.log("changed to month");
      } else {
        this.monthOryear = 12;
        this.monthOryearText = "Year";
        this.checked = true;
        console.log("changed to year");
      }
    },
  },
  created: function () {},
  mounted() {
    new WOW.WOW().init();
  },
};
</script>
