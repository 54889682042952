<template>
  <div>
    <!-- content begin -->
    <div class="no-bottom no-top" id="content">
      <div id="top"></div>

      <!-- section begin -->
      <section id="subheader">
        <div class="center-y relative text-center" data-scroll-speed="4">
          <div class="container">
            <div class="row">
              <div class="col-md-8 offset-md-2">
                <div class="spacer-single" style="background-size: cover"></div>
                <div class="row">
                  <div class="col-md-12 text-center">
                    <h1>Subscription</h1>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- section close -->

      <section class="no-top">
        <div class="container">
          <div class="row">
            <div class="col-md-8 widget">
              <h4>Subscription Details</h4>
              <div class="small-border"></div>
              <div>
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <label class="form-label">plan</label
                    ><select class="form-control custom-select">
                      <option value="0">Premium</option>
                      <option value="82">Business</option>
                      <option value="91" selected="selected">Business Pro</option>
                    </select>
                  </div>
                  <div class="col-md-12 col-lg-3">
                    <label class="form-label">Billed</label
                    ><select class="form-control custom-select">
                      <option value="0">monthly</option>
                      <option value="91" selected="selected">yearly</option>
                    </select>
                  </div>
                  <div class="col-md-12 col-lg-3">
                    <div class="form-group">
                      <label class="form-label">Users</label
                      ><input type="number" value="12" class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="form-group">
                      <label class="form-label">Email</label
                      ><input type="email" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="form-group">
                      <label class="form-label">Email Confirmation</label
                      ><input type="email" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="form-group">
                      <label class="form-label">Password</label
                      >
                      
                        <Password
                            v-model="value2"
                            toggleMask="true"
                            id="Passsword"
                          ></Password>

                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="form-group">
                      <label class="form-label">Password Confirmation</label
                      >
                       <Password
                            v-model="value3"
                            toggleMask="true"
                            id="Passsword"
                          ></Password>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="form-group">
                      <label class="form-label">Account Name</label>
                      <div class="input-group mb-3">
                        <input
                          type="text"
                          aria-describedby="basic-addon2"
                          placeholder="Account Name
"
                          class="form-control"
                        />
                        <div class="input-group-append">
                          <span id="basic-addon2" class="input-group-text"> .p2c.sa</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-12">
                    
      <div class="custom-control custom-checkbox p-0">
            <Checkbox id="binary" v-model="checked" :binary="true" />
            <label
              for="binary"
              class="p-checkbox-label   mb-0 ml-2 pt-1"
              style="font-weight: bold"
              >I agree to P2C 
                  <a href="/TermAndConditions" target="_blank"   >
              Terms and Conditions
                  </a>
              </label
            >
          </div>


                  </div>
                </div>
              </div>
            </div>
            <div id="sidebar" class="col-md-4">
              <div class="widget widget-post">
                <h4>Subscription Summary</h4>
                <div class="small-border"></div>
                <ul>
                  <li>
                    Business Pro Plan <br />
                    One year Subscription <br />
                    12 User<span class="float-right">SAR 10800.00 </span>
                  </li>
                  <!-- <li>Fees <span class="float-right">$ 0.00</span></li> -->
                  <li>Discount<span class="float-right">SAR 0.00</span></li>
                  <li>Tax<span class="float-right">SAR 0.00</span></li>
                  <li style="font-size: 24px; font-weight: bold">
                    Total<span class="float-right">SAR 10800.00</span>
                  </li>
                </ul>
                <div class="input-group mb-3">
                  <input
                    type="text"
                    aria-describedby="basic-addon2"
                    placeholder="Discount voucher
"
                    class="form-control"
                  />
                  <div class="input-group-append">
                    <span id="basic-addon2" class="btn btn-primary">Apply</span>
                  </div>
                </div>
                <div class="card-footer text-center">
                  <a href="" class="btn-custom d-block" style="width: 100%"
                    >Continue Checkout</a
                  >
                </div>
              </div>
              <div class="widget widget-text"></div>
            </div>
            <div class="spacer-double"></div>
          </div>
        </div>
      </section>
    </div>
    <preFooter></preFooter>
  </div>
</template>

<script>
// import $ from "jquery";

import WOW from "wowjs";
import preFooter from "@/components/home/preFooter.vue";
import Checkbox from "primevue/checkbox";
export default {
  name: "Subscription",
  data() {
    return {
      checked: false,
      
      value1: null,
      value2: null,
      value3: null,
      value4: null 
    };
  },
  components: {
    // PricingTable,
    // testimonial,
    Checkbox,
    preFooter,
  },
  methods: {
    functionName: function () {},
  },
  created: function () {},
  mounted() {
    new WOW.WOW().init();
    // this.$parent.custom_bg();
  },
};
</script>
