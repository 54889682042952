<template>
  <!-- content begin -->
  <div class="no-bottom no-top" id="content">
    <div id="top"></div>

    <!-- section begin -->
    <section id="subheader" data-bgimage="url(images/background/5.png) bottom">
      <div class="center-y relative text-center" data-scroll-speed="4">
        <div class="container">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <form
                action="blank.php"
                class="row"
                id="form_subscribe"
                method="post"
                name="myForm"
              >
                <div class="col text-center">
                  <div class="spacer-single"></div>
                  <h1>
                    {{ $t("Tour") }}
                  </h1>
                  <p v-if="$t('lang') == 'en'">
         learn how P2C solutions can support your business

                  </p>
                  <p v-if="$t('lang') == 'ar'">
                تعلم كيف يمكن لحلولنا أن تدعم أعمالك
                  </p>
                </div>
                <div class="clearfix"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- section close -->

    <section class="no-top">
      <div class="container">
        <div  >
          <div class="row justify-content-center">
            <div class="col-md-10"> 
              
             <video-embed src="https://www.youtube.com/embed/oGyoFYNlzdg"></video-embed>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- content close -->
</template>

<script>
 
// import testimonial from '@/components/home/testimonial.vue';
export default {
  name: "Home",
  data() {
    return {
      checked: false,
    };
  },
  components: {},
  methods: {},
  created: function () {},
  mounted() {
  

  },
};
</script>
