<template>
  <div>
    <!-- section begin -->
    <section
      id="section-pricing"
      class="no-top"
      data-bgimage="url(/Theme/images/background/3c.png) top"
    >
      <div class="container">
        <div class="row mb-4">
          <div class="col-md-8 offset-md-2">
            <div class="col text-center" v-if="$t('lang') == 'en'">
              <h1>Pricing</h1>
              <h4>For SME up to 50 users only</h4>
              <p class="mb-3">
                The easiest advanced solution to manage your strategic,
                projects, teams and tasks in our cloud computing or in your
                enterprise premise data center.
              </p>
            </div>
            <div class="col text-center" v-if="$t('lang') == 'ar'">
              <h1>الأسعار</h1>
              <h4>للشركات الصغيرة والمتوسطة حتى 50 مستخدم فقط</h4>
              <p class="mb-3">
                أحد الحلول الأسهل والأشمل والأكثر تقدمًا لإدارة الاستراتيجية
                والمشاريع والمهام بشكل متكامل ومتسلسل
              </p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col text-center">
            <div
              class="switch-set"
              style="transform: scale(1.3); margin-top: 16px"
            >
              <div>{{ $t("PricingData.Monthly") }}</div>
              <div>
                <input
                  v-model="checked"
                  @click="switchClick()"
                  class="switch"
                  value="true"
                  type="checkbox"
                />
              </div>
              <div>{{ $t("PricingData.Yearly") }}</div>
              <div class="spacer-20"></div>
            </div>
          </div>
          <div class="col text-center">
            <div class="switch-set">
              <div>{{ $t("PricingData.Users") }}</div>
              <div>
                <InputNumber
                  id="horizontal"
                  inputClass="usersCounter"
                  v-model="usersNumber"
                  showButtons
                  buttonLayout="horizontal"
                  mode="decimal"
                  :min="5"
                  :max="50"
                  decrementButtonClass="p-button-danger"
                  incrementButtonClass="p-button-success"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                />
              </div>
              <div><i class="fa fa-users"></i></div>
              <div class="spacer-20"></div>
            </div>
          </div>
        </div>
        <div class="item pricing">
          <div class="container">
            <div class="row">
              <div
                class="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay=".75s"
              >
                <div class="pricing-s1 mb30">
                  <div class="top" v-if="$t('lang') == 'en'">
                    <h2>Premium</h2>
                    <p class="plan-tagline">Tasks management</p>
                  </div>
                  <div class="top" v-if="$t('lang') == 'ar'">
                    <h2>التميز</h2>
                    <p class="plan-tagline">إدارة المهام</p>
                  </div>
                  <div class="mid text-light bg-color-3">
                    <div class="price">
                      <span class="m">{{
                        this.PremiumCost * this.monthOryear
                      }}</span>
                      <span class="currency">{{ $t("PricingData.SAR") }}</span>
                      <div class="month">
                        {{ $t("PricingData.User") }} /
                        {{ this.monthOryearText }}
                      </div>
                    </div>
                  </div>
                  <div class="mid">
                    <div class="text-total">
                      {{ $t("PricingData.TotalSAR") }}
                      {{
                        this.usersNumber * this.PremiumCost * this.monthOryear
                      }}
                      /
                      {{ this.monthOryearText }}
                    </div>
                  </div>
                  <div class="action">
                    <router-link v-if="showpricing==0" class="btn-custom btn-fullwidth " :to="'/'+$t('lang')+'/Subscription'">
                      <span class="top" v-if="$t('lang') == 'ar'">
                       إشترك </span>   
                       <span class="top" v-if="$t('lang') == 'en'">
                       Subscribe </span>   
                    </router-link>
                    <router-link v-else
                     :to="'/'+$t('lang')+'/Contact'" 
                      class="btn-custom btn-fullwidth"
                      > {{$t("Contact")}}</router-link
                    >
                  </div>

                  <div class="card-header bg-white shadow-sm border-0" style="background-size: cover;">
                    <h6 class="mb-0 font-weight-bold">
                      <a href="#" data-toggle="collapse" data-target="#collapse-a2xo" aria-expanded="false" 
                      class="d-block position-relative collapsed text-dark collapsible-link py-2"> {{ $t('Features') }}</a></h6></div>

  <div id="collapse-a2xo"   class="collapse  pt-2">

      <div class="bottom" v-if="$t('lang')=='en'">
                    <ul>
                      <li><i class="fa fa-check"></i>iOS and Android apps</li>
                      <li>
                        <i class="fa fa-check"></i>Web app with Responsive
                        Design
                      </li>
                      <li><i class="fa fa-check"></i>Tasks Kanban view</li>
                      <li>
                        <i class="fa fa-check"></i>Unlimited tasks, and
                        sub-tasks
                      </li>
                      <li><i class="fa fa-check"></i>Time tracking</li>
                      <li><i class="fa fa-check"></i>Unlimited Files</li>
                      <li><i class="fa fa-check"></i>Teams Quality KPI</li>
                      <li><i class="fa fa-check"></i>Teams Productivity KPI</li>
                      <li><i class="fa fa-check"></i>Unlimited comments</li>
                      <li><i class="fa fa-check"></i>Team’s chat</li>
                      <li><i class="fa fa-check"></i>Custom reminders</li>
                      <li>
                        <i class="fa fa-check"></i>Powerful reporting &amp;
                        dashboards
                      </li>
                      <li>
                        <i class="fa fa-check"></i>Dynamic Organizational
                        Structure (RBS)
                      </li>
                      <li><i class="fa fa-check"></i>Dependency Management</li>
                      <li>
                        <i class="fa fa-check"></i>Advanced Contacts Directory
                      </li>
                      <li><i class="fa fa-check"></i>Approvals</li>
                      <li><i class="fa fa-check"></i>Customizable workflows</li>
                      <li><i class="fa fa-check"></i>Advanced permissions</li>
                      <li>
                        <i class="fa fa-check"></i>Voice and video integration
                      </li>
                      <li>
                        <i class="fa fa-check"></i>E-Mail &amp; SMS integration
                      </li>
                    </ul>
                  </div>

                     <div class="bottom" v-if="$t('lang')=='ar'">
                    <ul>
                      <li><i class="fa fa-check"></i>
                      تطبيق الجوال (iOS & Android)

                      </li>
                      <li><i class="fa fa-check"></i>
                      واجهات مستخدم تلائم كافة الأجهزة

                      </li>
                        <li><i class="fa fa-check"></i>
                      تعدد اللغات

                      </li>
                        <li><i class="fa fa-check"></i>
                      لوحات عرض المهام

                      </li>
                        <li><i class="fa fa-check"></i>
                      عدد غير محدود من المهام والمهام الفرعية

                      </li>
                        <li><i class="fa fa-check"></i>
                      مراقبة الزمن

                      </li>
                        <li><i class="fa fa-check"></i>
                      عدد لا محدود من الملفات

                      </li>
                         <li><i class="fa fa-check"></i>
                      مؤشرات أداء الجودة للموظف ولفريق العمل وللمنظمة

                      </li>
                      <li><i class="fa fa-check"></i>
                      مؤشرات أداء الإنتاجية للموظف ولفريق العمل وللمنظمة

                      </li>
                        <li><i class="fa fa-check"></i>
                      عدد لا محدود من التعليقات والرسائل

                      </li>
                        <li><i class="fa fa-check"></i>
                      نظام محادثات بالنص والصورة

                      </li>
                        <li><i class="fa fa-check"></i>
                      أداة تنبيه بالايميل ورسائل الجوال

                      </li>
                        <li><i class="fa fa-check"></i>
                      لوحات التقارير والاحصائيات

                      </li>
                        <li><i class="fa fa-check"></i>
                      أداة بناء الهيكل التنظيمي للمؤسسة وتسكين الموظفين

                      </li>
                         <li><i class="fa fa-check"></i>
                      نظام تسلسل إداري

                      </li>
                      <li><i class="fa fa-check"></i>
                      دليل اتصالات متقدم

                      </li>
                        <li><i class="fa fa-check"></i>
                      نظام موافقات

                      </li>
                        <li><i class="fa fa-check"></i>
                      نظام تدفق عمل قابل للتخصيص

                      </li>
                        <li><i class="fa fa-check"></i>
                      نظام صلاحيات متعددة

                      </li>
                        <li><i class="fa fa-check"></i>
                      تكامل مع أنظمة الاتصالات بالصوت والفديو

                      </li>
                        <li><i class="fa fa-check"></i>
                      تكامل مع مزودي الإيميل ورسائل الجوال

                      </li>
                         
                    </ul>
                  </div>
 
 
 
  </div>
             
                </div>
              </div>

              <div
                class="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay=".25s"
              >
                <div class="pricing-s1 mb30">
                  <div class="top" v-if="$t('lang') == 'en'">
                    <h2>Business</h2>
                    <p class="plan-tagline">Strategy & Tasks Management</p>
                  </div>
                  <div class="top" v-if="$t('lang') == 'ar'">
                    <h2>أعمال</h2>
                    <p class="plan-tagline">إدارة المهام و الاستراتيجية</p>
                  </div>
                  <div class="mid text-light bg-color-2">
                    <div class="price">
                      <span class="m">{{
                        this.BusinessCost * this.monthOryear
                      }}</span>
                      <span class="currency">{{ $t("PricingData.SAR") }} </span>
                      <div class="month">
                        {{ $t("PricingData.User") }} /
                        {{ this.monthOryearText }}
                      </div>
                    </div>
                  </div>
                  <div class="mid">
                    <div class="text-total">
                      {{ $t("PricingData.TotalSAR") }}
                      {{
                        this.usersNumber * this.BusinessCost * this.monthOryear
                      }}
                      /
                      {{ this.monthOryearText }}
                    </div>
                  </div>
                  <div class="action">
                    <router-link v-if="showpricing==0" class="btn-custom btn-fullwidth " :to="'/'+$t('lang')+'/Subscription'">
                      <span class="top" v-if="$t('lang') == 'ar'">
                       إشترك </span>   
                       <span class="top" v-if="$t('lang') == 'en'">
                       Subscribe </span>   
                    </router-link>
                    <router-link v-else
                     :to="'/'+$t('lang')+'/Contact'" 
                      class="btn-custom btn-fullwidth"
                      > {{$t("Contact")}}</router-link
                    >
                  </div>

                           <div class="card-header bg-white shadow-sm border-0" style="background-size: cover;">
                    <h6 class="mb-0 font-weight-bold">
                      <a href="#" data-toggle="collapse" data-target="#collapse-a2xo" aria-expanded="false" 
                      class="d-block position-relative collapsed text-dark collapsible-link py-2"> {{ $t('Features') }}</a></h6></div>

  <div id="collapse-a2xo"   class="collapse pt-2">

                  <div class="bottom" v-if="$t('lang')=='en'">
                    Everything in Premium, plus:

                    <ul>
                      <li>
                        <i class="fa fa-check"></i> Unlimited Strategic Plans
                      </li>
                      <li><i class="fa fa-check"></i> Unlimited Strategy</li>
                      <li><i class="fa fa-check"></i> BSC Framework</li>
                      <li>
                        <i class="fa fa-check"></i> Unlimited Strategic Maps
                      </li>
                      <li><i class="fa fa-check"></i> Unlimited Content</li>
                      <li>
                        <i class="fa fa-check"></i> Unlimited Strategy KPIs
                      </li>
                      <li><i class="fa fa-check"></i> KPIs Integration</li>
                    </ul>
                  </div>

                      <div class="bottom" v-if="$t('lang')=='ar'"
>
كل مزايا الباقة المميزة بالإضافة إلى:

                    <ul>
                      <li>
                        <i class="fa fa-check"></i>
                        عدد لا محدود من الخطط الاستراتيجية

                      </li>
                        <li>
                        <i class="fa fa-check"></i>
                        عدد لا محدود من الخطط التنفيذية

                      </li>   <li>
                        <i class="fa fa-check"></i>
                        إطار بطاقات الأداء المتوازن
                      </li>   <li>
                        <i class="fa fa-check"></i>
                        عدد لا محدود من الخرائط الاستراتيجية

                      </li>   <li>
                        <i class="fa fa-check"></i>
                        عدد لا محدود من المحتوى

                      </li>   <li>
                        <i class="fa fa-check"></i>
                        عدد لا محدود من مؤشرات الأداء

                      </li>   <li>
                        <i class="fa fa-check"></i>
                        تكامل مؤشرات الأداء من أنظمة المؤسسة الأخرى

                      </li>   
                    </ul>
                  </div>

  </div>




                </div>
              </div>
              <div
                class="col-lg-4 col-md-6 col-sm-12 wow fadeInUp"
                data-wow-delay="0s"
              >
                <div class="pricing-s1 mb30">
                  <div class="top" v-if="$t('lang')=='en'"
>
                    <h2>Business Pro</h2>
                    <p class="plan-tagline">
                      Strategy, Projects & Tasks Management
                    </p>
                  </div>
                  <div class="top" v-if="$t('lang')=='ar'"
>
                    <h2>أعمال برو</h2>
                    <p class="plan-tagline">
                      إدارة المهام , الاستراتيجية والمشاريع
                    </p>
                  </div>

                  <div class="mid text-light bg-color">
                    <div class="price">
                      <span class="m">{{
                        this.BusinessProCost * this.monthOryear
                      }}</span>
                      <span class="currency">{{ $t("PricingData.SAR") }}</span>
                      <div class="month">
                        {{ $t("PricingData.User") }} /
                        {{ this.monthOryearText }}
                      </div>
                    </div>
                  </div>
                  <div class="mid">
                    <div class="text-total">
                      {{ $t("PricingData.TotalSAR") }}
                      {{
                        this.usersNumber *
                        this.BusinessProCost *
                        this.monthOryear
                      }}
                      /
                      {{ this.monthOryearText }}
                    </div>
                  </div>
                  <div class="action">
                    <router-link v-if="showpricing==0" class="btn-custom btn-fullwidth " :to="'/'+$t('lang')+'/Subscription'">
                      <span class="top" v-if="$t('lang') == 'ar'">
                       إشترك </span>   
                       <span class="top" v-if="$t('lang') == 'en'">
                       Subscribe </span>   
                    </router-link>
                    <router-link v-else
                     :to="'/'+$t('lang')+'/Contact'" 
                      class="btn-custom btn-fullwidth"
                      > {{$t("Contact")}}</router-link
                    >
                  </div>

                           <div class="card-header bg-white shadow-sm border-0" style="background-size: cover;">
                    <h6 class="mb-0 font-weight-bold">
                      <a href="#" data-toggle="collapse" data-target="#collapse-a2xo" aria-expanded="false" 
                      class="d-block position-relative  collapsed text-dark collapsible-link py-2">
                {{ $t('Features') }}
                      </a></h6></div>

  <div id="collapse-a2xo"   class="collapse pt-2 ">

                  <div class="bottom" v-if="$t('lang')=='en'"
>
                    Everything in Premium, plus:

                    <ul>
                      <li>
                        <i class="fa fa-check"></i> Unlimited Portfolios,
                        Programs & Projects
                      </li>
                      <li>
                        <i class="fa fa-check"></i> Project Management Standards
                      </li>
                      <li>
                        <i class="fa fa-check"></i> Project team with
                        multi-rules
                      </li>
                      <li>
                        <i class="fa fa-check"></i> Project Communication Plan
                      </li>
                      <li>
                        <i class="fa fa-check"></i> Project Risk Management
                      </li>
                      <li>
                        <i class="fa fa-check"></i> Stakeholder Management
                      </li>
                      <li>
                        <i class="fa fa-check"></i> Schedule Time and Gantt
                        Charts
                      </li>
                      <li>
                        <i class="fa fa-check"></i> Schedule Integration with
                        Tasks Kanban
                      </li>
                      <li>
                        <i class="fa fa-check"></i> Project Reports & KPIs
                      </li>
                      <li><i class="fa fa-check"></i> archiving</li>
                    </ul>
                  </div>

                    <div class="bottom" v-if="$t('lang')=='ar'"
>
                   كل مزايا باقة الأعمال بالإضافة إلى:


                    <ul>
                      <li>
                        <i class="fa fa-check"></i> 
                        عدد لا محدود من محافظ وبرامج المشاريع والمشاريع

                      </li>
                      <li>
                        <i class="fa fa-check"></i> 
                        التوافق مع معايير إدارة المشاريع

                      </li>
                        <li>
                        <i class="fa fa-check"></i> 
                        فرق عمل المشروع بصلاحيات متعددة

                      </li>
                        <li>
                        <i class="fa fa-check"></i> 
                        خطط الاتصالات

                      </li>
                        <li>
                        <i class="fa fa-check"></i> 
                        خطط إدارة المخاطر

                      </li>
                        <li>
                        <i class="fa fa-check"></i> 
                        خطط إدارة المعنيين بالمشروع

                      </li>
                        <li>
                        <i class="fa fa-check"></i> 
                        الجداول الزمنية للمشاريع مع مخططات قانت 

                      </li>
                        <li>
                        <i class="fa fa-check"></i> 
                        تكامل مهام المشروع مع نظام إدارة المهام

                      </li>
                              <li>
                        <i class="fa fa-check"></i> 
                        تقارير حالة متنوعة لكل مشرع مع مؤشرات الأداء 

                      </li>
                              <li>
                        <i class="fa fa-check"></i> 
                        أرشفة المشاريع

                      </li>
                            
                    </ul>
                  </div>

  </div>



                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="col-lg-6 col-md-6 col-sm-12 wow fadeInRight"
                data-wow-delay=".75s"
              >
                <div class="pricing-s1 mb30"> 
                  <div class="top"><h2>{{$t("PricingData.Enterprise")}} </h2></div>
                  <div class="mid text-light bg-color-3">
                    <div class="price"><span class="m"> {{$t("Contact")}} </span></div>
                  </div>
                  <div class="mid">
                    <div class="bottom" v-if="$t('lang')=='en'"
>
                      Everything in Business Pro, plus

                      <ul>
                        <li>
                          <i class="fa fa-check"></i>Comprehensive solution for
                          Enterprise business with advanced security,
                          integration &amp; controls
                        </li>
                      </ul>
                    </div>
                       <div class="bottom" v-if="$t('lang')=='ar'"
>
                   كل مزايا باقة الأعمال برو بالإضافة إلى:

                      <ul>
                        <li>
                          <i class="fa fa-check"></i>حل شامل للأعمال التجارية في بيئة استضافة سحابية مستقلة مع نظام أمن معلومات عالي وتكامل مع أنظمة المؤسسة الأخرى وامكانية التخصيص حسب احتياجات كل منظمة

                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="action">
                    <router-link
                     :to="'/'+$t('lang')+'/Contact'" 
                      class="btn-custom btn-fullwidth"
                      >
                      {{$t('PricingData.RequestDemo')}}
                      
                      </router-link
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-lg-6 col-md-6 col-sm-12 wow fadeInLeft"
                data-wow-delay=".75s"
              >
                <div class="pricing-s1 mb30">
                  <div class="top"><h2>{{$t("PricingData.Corporate")}}</h2></div>
                  <div
                    class="mid text-light bg-color-3"
                    style="background: #006864"
                  >
                    <div class="price"><span class="m"> {{$t("Contact")}} </span></div>
                  </div>
                  <div class="mid">
                    <div class="bottom" v-if="$t('lang')=='en'"
>
                      Everything in Business Pro, plus

                      <ul>
                        <li>
                          <i class="fa fa-check"></i>Comprehensive solution for
                          Corporate business with advanced security, integration
                          & controls
                        </li>
                      </ul>
                    </div>
                       <div class="bottom" v-if="$t('lang')=='ar'"
 >
                    كل مزايا باقة الأعمال برو بالإضافة إلى:


                      <ul>
                        <li>
                          <i class="fa fa-check"></i>
                          
                      حل شامل لمجموعات الأعمال (مركز رئيسي وشركات/مؤسسات/فروع تابعة) في بيئة استضافة سحابية مستقلة مع نظام أمن معلومات عالي وتكامل مع أنظمة المؤسسة الأخرى وامكانية التخصيص حسب احتياجات كل منظمة

                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="action">
                    <router-link
                      :to="'/'+$t('lang')+'/Contact'" 
                      class="btn-custom btn-fullwidth"
                      >
                      {{$t('PricingData.RequestDemo')}}
                      </router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import SAR from "jquery";

import WOW from "wowjs";

export default {
  name: "Home",
  data() {
    return {
      checked: false,
      usersNumber: 5,
      PremiumCost: 37,
      BusinessCost: 57,
      BusinessProCost: 75,
      monthOryear: 1,
      monthOryearText: "Month",
      Month: "Month",
      Year: "Year",
    };
  },
  components: {},
  methods: {
    switchClick: function () {
      if (this.checked == true) {
        this.monthOryear = 1;
        this.monthOryearText = this.Month;
        this.checked = false;
      } else {
        this.monthOryear = 12;
        this.monthOryearText = this.Year;
        this.checked = true;
      }
    },
  },
  props:{
    showpricing:{
      type:Number,
      default:1
    }
  },

  created: function () {},
  mounted() {
    new WOW.WOW().init();
    if (localStorage.getItem("loc") == "ar") {
      this.Year = "سنة";
      this.Month = "شهر";
      this.monthOryearText = "شهر";
    }
    if (localStorage.getItem("loc") == "en") {
      this.Year = "Year";
      this.Month = "Month";
      this.monthOryearText = "Month";
    }
  },
};
</script>
<style scoped>
.pricing-s1 .action { 
    margin-bottom: 1px;
}
</style>