<template>
  <div :class="LangRtlClass">
    <div
      class="
        position-relative
        overflow-hidden
        p-3 p-md-2
        mb-md-5 
        bg-light
      "
    >
      <div class="container mx-auto my-5">
        <h1 class="display-4 font-weight-normal"  v-if="$t('lang') == 'en'">P2C
            |
            
             Terms and Conditions</h1>
                <h1 class="display-4 font-weight-normal"  v-if="$t('lang') == 'ar'">P2C
            |
            
            الشروط والأحكام</h1>
        <div class="row">
          <div class="col-sm-6">
            <p class="lead font-weight-normal" v-if="$t('lang') == 'en'">Last Updated: July 25, 2021</p>
            <p class="lead font-weight-normal" v-if="$t('lang') == 'ar'">آخر تحديث: 13 يوليو 2021</p> 
          </div>
          <div class="col-sm-6 text-right">
            
               <a href="/Docs/P2C Terms and Conditions Arabic V1.0.pdf" v-if="$t('lang') == 'ar'" download  class="btn btn-outline-secondary " 
            style="font-size: 14px;height: 31px;margin-top: -2px;">تحميل PDF
            </a>
            <a href="/Docs/P2C Term and Conditions English V1.0.pdf" v-if="$t('lang') == 'en'" download  class="btn btn-outline-secondary " 
            style="font-size: 14px;height: 31px;margin-top: -2px;">Download PDF
            </a>
            <select 
              @change="onChange($event)"
              class="form-control d-inline-block  m-2"
              v-model="keyLang"
              style="width:100px ; font-size: 14px; height: 32px;"
            >
              <option value="1">English</option>
              <option value="2">العربية</option>
            </select>
          </div>
        </div>
      </div>
  
    </div>

        <div class="container">
             <contentAR v-if="$t('lang') == 'ar'"></contentAR>
             <contentEN v-if="$t('lang') == 'en'"></contentEN>
        </div>

  
  </div>
</template>


<script>
import contentAR from "@/views/Terms/contentAR.vue";
import contentEN from "@/views/Terms/contentEN.vue";
export default {
  name: "Terms", 
  components: {
    contentAR,
    contentEN 
  },
  data() {
    return {
      LangRtlClass: "lang-rtl",
      currentLang: localStorage.getItem("loc"),
      keyLang: "2",
    };
  },
  methods: {
    onChange(event) {
      var self = this;
     console.log(event.target.value)
      this.keyLang = event.target.value;
      if (this.keyLang == "1") {
        localStorage.setItem("loc", "en");
        self.$i18n.locale = "en"; 
        this.LangRtlClass = "lang-ltr";
      } else  if (this.keyLang == "2") {
        localStorage.setItem("loc", "ar");
        self.$i18n.locale = "ar"; 
        this.LangRtlClass = "lang-rtl";
      }
    },
  },
  mounted() {
      var self = this;
    if (this.currentLang == "en") {
      this.LangRtlClass = "lang-ltr";
      self.$i18n.locale = "en"; 
      this.keyLang = "1";
    } else {
      this.LangRtlClass = "lang-rtl";
      self.$i18n.locale = "ar"; 
      this.keyLang = "2";
    }
    if (window.location.href.indexOf("/en/") > -1) {
        localStorage.setItem("loc", "en");
        self.$i18n.locale = "en"; 
        this.LangRtlClass = "lang-ltr";
        this.keyLang = "1";
    }
    else if (window.location.href.indexOf("/ar/") > -1) {
        localStorage.setItem("loc", "ar");
        self.$i18n.locale = "ar"; 
        this.LangRtlClass = "lang-rtl";
        this.keyLang = "2";
    } 
 
  },
};
</script>
<style scoped>
.lang-rtl {
  direction: rtl;
  text-align: right;
}
.lang-ltr {
  direction: ltr;
  text-align: left;
}

.lang-rtl .text-right{
    text-align: left !important;
}
.lang-ltr .text-right{
    text-align: right !important;
}h1 {
    font-size: 20px;
    font-weight: bold;
}

h2 {
    font-size: 18px;
}

h3 {
    font-size: 16px;
}

p {
    font-size: 14px;
}

h1.display-4 {
    font-size: 30px;
}
</style>