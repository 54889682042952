<template>
     <div class="no-bottom " id="content">
        <div id="top"></div>
        <section
            id="section-highlight"
            class="no-top"
            data-bgimage="url(/Theme/images/background/3d.png) top">
            <div class="container">
                <div class="row row mt-4 mb-4 pt-4 pb-4" v-if="$t('lang')=='en'" >
                    <div class="col-md-6 offset-md-3">
                        <div class="text-center">
                            <h2><span class="uptitle id-color">System Features
                            </span>Achieve Your Business Strategic Goals</h2>
                            <div class="spacer-20"></div>
                        </div>
                    </div>
                </div>
                <div class="row row mt-4 mb-4 pt-4 pb-4" v-if="$t('lang')=='ar'" >
                    <div class="col-md-6 offset-md-3">
                        <div class="text-center">
                            <h2><span class="uptitle id-color">مزايا النظام
                            </span>حقق أهداف عملك الاستراتيجية</h2>
                            <div class="spacer-20"></div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="$t('lang')=='en'" >
                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay="0s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_03.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>Cloud-Based System
                                </h4>
                                <p>
                                    P2C is a cloud-based system giving real time visibility to evaluate achievements in order to reach the business strategic objectives.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".25s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_05.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>Integration</h4>
                                <p>
                                    P2C is designed to integrate with all business essential systems, such as ERP and EPM, through fast APIs. 
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".5s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_09.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>Easy user experience
                                </h4>
                                <p>
                                    Your team will be confident when using the P2C platform in both Web and mobile software with easy to navigate pages...
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay="0s">
                        <div class="f-box f-icon-left f-icon-rounded">

                            <div>
                                <img src="/Theme/images/fe-icons/icons_10.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>Organizational Chart
                                </h4>
                                <p>
                                    Build your own organizational chart with allocated teams and employees, or import it from your ERP system. By default, authority, workflow and task management...
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".25s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_13.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>Multi-language support
                                </h4>
                                <p>
                                    P2C comes by default with Arabic and English languages support, but other languages can be easily added as needed.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".5s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_14.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>Workflows</h4>
                                <p>
                                    Go fast with simple and powerful workflow; adopt P2C ready to-use workflows, customize them, or create your own to automate your processes.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".5s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_18.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>Multi-view
                                </h4>
                                <p>
                                    A faster, easier way to work with P2C task management with three viewing options: visual task Boards (Kanban); tasks list; and visual task Gantt-chart on the calendar.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".5s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_17.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>Multi and Real-time visibility

                                </h4>
                                <p>
                                    Keep your organization communication always transparent at all levels to lead your teams to take action and feel ownership over your organizational strategic objectives and its initiatives and tasks.

                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".5s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_21.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>Kanban Boards


                                </h4>
                                <p>
                                    Use P2C Kanban Boards to visualize your tasks. Move them through multiple stages easily, quickly, and in beautiful color. The task board displays records as “cards” in a drag‑and‑drop interface, allowing you to rapidly change its state, progress, or add reminders.


                                </p>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".5s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_22.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>Powerful visualization Dashboard


                                </h4>
                                <p>
                                    Executive level dashboards, and customizable KPIs that compare strategy execution targets with actual achievements, with the most important KPIs for each Balanced Scorecard.


                                </p>
                            </div>
                        </div>
                    </div>




                </div>

                <div class="row" v-if="$t('lang')=='ar'" >
                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay="0s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_03.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>منصة عمل سحابية
                                </h4>
                                <p>
نظام (P2C) هو منصة عمل سحابية تمنحك في الوقت الفعلي رؤية لتقييم إنجازات منظمتك نحو تحقيق أهدافها الاستراتيجية..

                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".25s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_05.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>التكامل</h4>
                                <p>
تم تصميم منصة (P2C) للتكامل عند الحاجة مع نظم تخطيط الموارد (ERP) ونظم إدارة المشاريع الأخرى (EPM)

                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".5s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_09.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>تجربة مستخدم سهلة وممتعة
                                </h4>
                                <p>
منصة سهلة الفهم والاستخدام وذات شكل جذاب يجعل من فريق العمل واثقا ومستمتعا عند استخدامها والتنقل خلالها، والاستفادة من نظام التنبيهات ومؤشرات الأداء الشخصية..

                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay="0s">
                        <div class="f-box f-icon-left f-icon-rounded">

                            <div>
                                <img src="/Theme/images/fe-icons/icons_10.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>أداة الهيكل التنظيمي (Organizational Chart)


                                </h4>
                                <p>
يمكن بناء الهيكل التنظيمي للمنظمة واسكان الموظفين في الإدارات والأقسام والفروع المختلفة أو في لجان العمل، كما يمكن ربط واستيراد بيانات الموظفين مباشرة من نظام (ERP)، بشكل افتراضي الكثير من الصلاحيات وتدفق العمل تسير وفقا للتسلسل الوظيفي في هذا الهيكل.

                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".25s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_13.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>دعم عدة لغات

                                </h4>
                                <p>
بشكل افتراضي تدعم المنصة اللغة العربية والانجليزية، مع قابلية إضافة العديد من اللغات الأخرى عند احتياج عميل المنصة.

                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".5s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_14.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>أداة تدفق العمل (Workflows)
</h4>
                                <p>
يمكن الانطلاق بشكل أسرع مع الإجراءات البسيطة المعرفة سابقا، كما يتيح نظام تدفق العمل بشكل سهل وقوي تعديل مسارات تلك الإجراءات وتغيير أزمنة الأنشطة والمسؤولين عن تنفيذها سواء كانو مستخدمين أو إدارات، كما تمنح مؤشرات قياس الأداء التشغيلية تقييم أداء هذه الإجراءات لغرض التحسين المستمر..

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".5s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_18.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>تعدد طرق العرض

                                </h4>
                                <p>
يمكن الاختيار بين ثلاث طرق مختلفة لعرض المهام والتعامل معها، إما من خلال البطاقات القابلة للسحب والافلات أو من خلال القوائم أو على صفحات التقويم الأسبوعي أو الشهري.

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".5s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_17.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>رؤية متعددة، في الوقت الحقيقي

                                </h4>
                                <p>
المحافظة على الشفافية في اتصالات المنظمة يقود الموظفين لأخذ روح المبادرة والشعور بتبني أهدافها الاستراتيجية التنظيمية ومبادراتها ومهامها، وهذا ما تتيحه المنصة من خلال ربط عرض مهام الموظف بالإدارة والإدارات بالمنظمة وربط ذلك كله بمؤشرات حقيقية آلية..


                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".5s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_21.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>لوحات المهام



                                </h4>
                                <p>
تعرض المهام في بطاقات مقسمة على ثلاث لوحات رئيسية هي المهام الجديدة والمهام التي تحت الإنجاز والمهام المنجزة، ويمكن سحب البطاقة من لوحة إلى أخرى أو تغيير حالتها من داخل تفاصيل المهمة، كما توضح مدلوليه ألوان البطاقات وأيقوناتها نوع المهمة وحالتها الزمنية، كما يمكن إضافة تنبيهات متعددة لتذكير الموظف بمهامه.



                                </p>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-6 col-md-6 mb30 wow fadeInRight" data-wow-delay=".5s">
                        <div class="f-box f-icon-left f-icon-rounded">
                            <div>
                                <img src="/Theme/images/fe-icons/icons_22.png" class="feature-img" />
                            </div>
                            <div class="fb-text">
                                <h4>لوحة مؤشرات قابلة للتخصيص



                                </h4>
                                <p>
تتيح المنصة ملخص تنفيذي قابل للتخصيص يقارن مستهدفات الاستراتيجية بالإنجازات الفعلية، مع عرض أهم مؤشرات الأداء الرئيسية لكل بطاقة أداء متوازن، كما تتيح التقارير والمؤشرات الرسومية عالية الدقة الحصول على نظرة ثاقبة حول أداء المنظمة وأعمالها وموظفيها..



                                </p>
                            </div>
                        </div>
                    </div>




                </div>


            </div>
        </section>

    </div>
</template>


<script>
// import $ from "jquery";
 
import WOW from "wowjs";
// import testimonial from '@/components/home/testimonial.vue';
export default {
  name: "Home",
  data() {
    return {
      checked: false,
    };
  },
  components: {},
  methods: { 
  },
  created: function () {},
  mounted() {
    new WOW.WOW().init();
     this.$parent.custom_bg();
 
  },
};
</script>
