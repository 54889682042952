<template>
  <!-- content begin -->
  <div class="no-bottom no-top" id="content">
    <div id="top"></div>

    <!-- section begin -->
    <section id="subheader" data-bgimage="url(images/background/5.png) bottom">
      <div class="center-y relative text-center" data-scroll-speed="4">
        <div class="container">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <form
                action="blank.php"
                class="row"
                id="form_subscribe"
                method="post"
                name="myForm"
              >
                <div class="col text-center">
                  <div class="spacer-single"></div>
                  <h1>
                    {{ $t("Partners") }}
                  </h1>
                  <p v-if="$t('lang') == 'en'">
                    Teamwork Begins By Building Trust
                  </p>
                  <p v-if="$t('lang') == 'ar'">
                    العمل الجماعي يبدأ ببناء الثقة
                  </p>
                </div>
                <div class="clearfix"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- section close -->

    <section class="no-top">
      <div class="container">
        <div class="padding40 round wow fadeInLeft" data-wow-delay=".5s">
          <div class="row align-items-center">
            <div class="col-md-2">
              <h4 class="no-bottom">{{ $t("Partners") }}</h4>
            </div>
            <div class="col-md-10">
              <div id="owl-logo" class="logo-carousel owl-carousel owl-theme" style="direction:ltr;text-align:left">
                <img
                  src="/assets/images/partner/1.jpg"
                  class="img-fluid"
                  alt=""
                />
                <img
                  src="/assets/images/partner/2.jpg"
                  class="img-fluid"
                  alt=""
                />
                <img
                  src="/assets/images/partner/3.jpg"
                  class="img-fluid"
                  alt=""
                />
                <img
                  src="/assets/images/partner/4.jpg"
                  class="img-fluid"
                  alt=""
                />
                <img
                  src="/assets/images/partner/5.jpg"
                  class="img-fluid"
                  alt=""
                />
                <img
                  src="/assets/images/partner/6.jpg"
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- content close -->
</template>

<script>
// import $ from "jquery";
import $ from "jquery";
import WOW from "wowjs";
// import testimonial from '@/components/home/testimonial.vue';
export default {
  name: "Home",
  data() {
    return {
      checked: false,
    };
  },
  components: {},
  methods: {},
  created: function () {},
  mounted() {
    new WOW.WOW().init();
    this.$parent.custom_bg();
    $("#owl-logo").owlCarousel({
      center: false,
      items: 6,
      loop: true,
      dots: false,
      margin: 30,
      autoplay: true,
      autoplayTimeout: 2000,
      responsive: {
        1000: {
          items: 4,
        },
        600: {
          items: 3,
        },
        0: {
          items: 1,
        },
      },
    });
  },
};
</script>
