<template>
  <!-- content begin -->
  <div class="no-bottom no-top" id="content">
    <div id="top"></div>

    <!-- section begin -->
    <section
      id="subheader"
      v-if="$t('lang') == 'en'"
      data-bgimage="url(images/background/5.png) bottom"
    >
      <div class="center-y relative text-center" data-scroll-speed="4">
        <div class="container">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <form
                action="blank.php"
                class="row"
                id="form_subscribe"
                method="post"
                name="myForm"
              >
                <div class="col text-center">
                  <div class="spacer-single"></div>
                  <h1>Solutions</h1>
                  <p>Boost Your Team’s Success</p>
                </div>
                <div class="clearfix"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      id="subheader"
      v-if="$t('lang') == 'ar'"
      data-bgimage="url(images/background/5.png) bottom"
    >
      <div class="center-y relative text-center" data-scroll-speed="4">
        <div class="container">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <div class="row">
                <div class="col text-center">
                  <div class="spacer-single"></div>
                  <h1>الحـلول</h1>
                  <p>عزز نجاح فريقك</p>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- section close -->

    <section class="no-top" v-if="$t('lang') == 'en'">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-7 wow fadeInRight" data-wow-delay=".5s">
            <h3>What is P2C? & Why do you need it?</h3>
            <p>
              P2C is a set of cloud-based integrated business management
              modules, enabling teams to collaborate efficiently to achieve
              business strategic goals. It also enables senior leaders to
              evaluate the KPIs’ results to align the business initiatives and
              projects based on the four perspectives of the balanced scorecard
              (Financial, Customer, Internal Process, and Learning and Growth).
            </p>
            <strong> The P2C solution includes the following modules:</strong>
            <ul>
              <li>Strategic and Operation Management</li>
              <li>Organizational Project Management</li>
              <li>Tasks Management</li>
              <li>Documents and Knowledge Management</li>
            </ul>
          </div>
          <div class="col-md-5 wow fadeInLeft" data-wow-delay=".5s">
            <img src="/Theme/images/misc/7.png" class="img-fluid" alt="" />
          </div>
        </div>

        <div class="spacer-double"></div>

        <div class="row large-fm">
          <div
            class="col-lg-6 col-md-6 mb30 wow fadeInRight"
            data-wow-delay="0s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_09.png"
                  class="feature-img"
                />
              </div>

              <div class="fb-text">
                <p>
                  P2C can be customized according to the customer's needs, along
                  with other essential system tools such as
                  multiple-authentication, workflow, organization structure
                  tool, and the alerts tool. All of these functions and features
                  come in a Web responsive design and mobile app through Apple
                  and Google stores. The System can be implemented on the
                  external cloud hosting, or on the client's premises servers.
                </p>
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-6 mb30 wow fadeInLeft"
            data-wow-delay="1s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_22.png"
                  class="feature-img mr-5"
                />
              </div>
              <div class="fb-text">
                <p>
                  P2C allows adding and linking the strategic plan elements with
                  the execution objectives, KPIs, initiatives, projects and
                  tasks according to a clear strategic map. The strategic map
                  sequence sends values to the KPIs to show progress and
                  performance and compare targets with actual achievements.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-lg-12 col-md-12 mb30 wow fadeInRight"
            data-wow-delay="1s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div class="fb-text">
                <p>
                  P2C represents one of the easiest, comprehensive and advanced
                  solutions for managing strategy, projects and tasks, with
                  linked smart KPIs that show the state of progress towards
                  achieving the business goals. P2C is an innovative choice to
                  enable the organizational strategy, operations and work teams
                  to communicate and interact in a one cloud work platform.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="spacer-double"></div>

        <div class="row align-items-center justify-content-center">
          <div class="col-md-7 text-center wow fadeInUp" data-wow-delay=".5s">
            <h3>Main P2C Modules</h3>

            <img src="/assets/images/diagrams/2.png" class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>

    <section class="no-top" v-if="$t('lang') == 'ar'">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-7 wow fadeInRight" data-wow-delay=".5s">
            <h3>ماهو نظام الاستراتيجية والمشاريع المهام؟</h3>
            <p>
              نظام إدارة الاستراتيجية والمشاريع والمهام (P2C) عبارة عن منصة
              سحابية تجمع مجموعة من الأدوات المتكاملة لإدارة الأعمال، تمكن فرق
              العمل في المنظمة من التعاون بفاعلية لتحقيق الأهداف الاستراتيجية،
              وتمكن القيادات العليا من تقييم وموائمة مسار العمل من خلال مؤشرات
              تعنى بجوانب الإنجاز والإنتاجية والجودة مرتبطة ببطاقات الأداء
              المتوازن الأربع (المالية، والعملاء أو المستفيدين، والعمليات
              الداخلية، والتعلم والنمو،
            </p>
            <strong>تتضمن هذه الأدوات ما يلي: </strong>
            <ul>
              <li>إدارة الاستراتيجية والتشغيل</li>
              <li>إدارة المشاريع المؤسسية</li>
              <li>إدارة المهام</li>
              <li>إدارة المعرفة والملفات</li>
            </ul>
          </div>
          <div class="col-md-5 wow fadeInLeft" data-wow-delay=".5s">
            <img src="/Theme/images/misc/7.png" class="img-fluid" alt="" />
          </div>
        </div>

        <div class="spacer-double"></div>

        <div class="row large-fm">
          <div
            class="col-lg-6 col-md-6 mb30 wow fadeInRight"
            data-wow-delay="0s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_09.png"
                  class="feature-img"
                />
              </div>

              <div class="fb-text">
                <p>
                  نظام مبتكر لإدارة تنفيذ الاستراتيجية يعمل على تمكين فرق العمل
                  من اتخاذ الإجراءات والشعور بتبني أهداف المنظمة الاستراتيجية
                  ومبادراتها ومشاريعها، النظام باختصار يمنح في الوقت الفعلي رؤية
                  لتقييم الإنجازات نحو تحقيق الأهداف الاستراتيجية، ويتيح إضافة
                  عناصر الخطة الاستراتيجية وربطها بالأهداف التنفيذية والمبادرات
                  والمشاريع والمهام وفقًا لخريطة استراتيجية سهلة وواضحة، ضمن
                  تسلسل صحيح يعرض مؤشرات الأداء التي تقارن القيم المستهدفة
                  بالإنجازات الفعلية.
                </p>
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-6 mb30 wow fadeInLeft"
            data-wow-delay="1s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_22.png"
                  class="feature-img mr-5"
                />
              </div>
              <div class="fb-text">
                <p>
                  يمكن هذا النظام الموظفين بكافة مستوياتهم من إدارة المهام بشكل
                  تعاوني تفاعلي وفقا للتسلسل الإداري، كما يساعد على الانضباط في
                  تنفيذ الأعمال حسب الوقت والجودة المطلوبة، تم تصميم النظام بعدة
                  خيارات لعرض المهام، كما تم ربط كافة المهام بمؤشرات قياس الجودة
                  والإنتاجية وسرعة الاستجابة على مستوى الموظف والفريق والإدارة
                  والمنظمة.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-lg-12 col-md-12 mb30 wow fadeInRight"
            data-wow-delay="1s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div class="fb-text">
                <p>
                  باختصار النظام يمثل أحد الحلول الأسهل والأشمل والأكثر تقدمًا
                  لإدارة الاستراتيجية والمشاريع والمهام بشكل متكامل ومتسلسل مع
                  ربط ذلك كله بمؤشرات قياس ذكية توضح حالة التقدم نحو تحقيق
                  الأهداف، إنه الخيار المبتكر لتمكين الاستراتيجية التنظيمية
                  والعمليات وفرق العمل كلها من الاتصال والتفاعل في منصة عمل
                  سحابية واحدة.
                </p>
              </div>
            </div>
          </div>
        </div> 

        <div class="spacer-double"></div>

        <div class="row align-items-center justify-content-center">
          <div class="col-md-7 text-center wow fadeInUp" data-wow-delay=".5s">
            <h3>وحدات P2C الرئيسية</h3>

            <img src="/assets/images/diagrams/2.png" v-if="$t('lang')=='en'"
                    class="img-fluid" alt="" />
                       <img src="/assets/images/diagrams/2-ar.png" v-if="$t('lang')=='ar'"
                    class="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>


  
    <!-- section close -->
    
    <section class="no-top" v-if="$t('lang') == 'en'">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-5 wow fadeInRight" data-wow-delay=".5s">
            <h3>Strategic Management
</h3>
            <p>
            P2C is an innovative strategy execution platform that empowers your teams to take action and feel ownership over your organizational strategic objectives and its initiatives. It is a cloud-based system giving you real time visibility to evaluate your achievements towards reaching your strategic goals. P2C allows you to add and link the strategic plan elements with the execution goals, initiatives, projects and tasks according to an easy and clear strategic map. In this sequence, it shows you performance indicators that compare targets with actual achievements.

            </p>
            
          </div>
          <div class="col-md-7 wow fadeInLeft" data-wow-delay=".5s">
            <img src="/assets/images/screens/screens-ar-1.jpg" class="img-fluid" alt="" />
          </div>
        </div>

         <div class="row align-items-center">
             <div class="col-md-7 wow fadeInLeft" data-wow-delay=".5s">
            <img src="/assets/images/screens/screens-ar-2.jpg" class="img-fluid" alt="" />
          </div>
          <div class="col-md-5 wow fadeInRight" data-wow-delay=".5s">
            <h3>Project management

</h3>
            <p>
The Integrated project portfolio system and techniques in P2C will enable your project managers to constantly deliver high quality project results, which will instantly keep both yourself and the executive leaders aware of the details and the status of your projects. The system will help you ensure your organizational project portfolios remain aligned with strategic goals, and that all projects are delivered successfully.

            </p>
            
          </div>
        
        </div>

        <div class="spacer-double"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-11 text-center wow fadeInUp" data-wow-delay=".5s">
            <h3>
              Project Management Methodology
              <br />
              Methodology in a system
            </h3>

            <img src="/assets/images/diagrams/1.png" class="img-fluid" alt="" />
          </div>
        </div>


       <div class="row align-items-center">
          <div class="col-md-5 wow fadeInRight" data-wow-delay=".5s">
            <h3>Tasks Management

</h3>
            <p>
Embrace P2C advance tasks management with confidence! It is a faster and easier way to manage all the organization tasks in various stages of a process, with KPIs to measure the responsive rate, quality and productivity at all different levels. All tasks can be represented in three viewing options: visual task Boards (Kanban); tasks list; and visual task Gantt-chart on the calendar. The Kanban view allows you to rapidly, by drag‑and‑drop, change a task’s state, change its progress, or add reminders. You can assign individual or teams’ tasks, and also attach files or threads of comments and discussions.

            </p>
            
          </div>
          <div class="col-md-7 wow fadeInLeft" data-wow-delay=".5s">
            <img src="/assets/images/screens/screens-ar-3.jpg" class="img-fluid" alt="" />
          </div>
        </div>



       <div class="row align-items-center">
         
          <div class="col-md-7 wow fadeInLeft" data-wow-delay=".5s">
            <img src="/assets/images/screens/screens-ar-4.jpg" class="img-fluid" alt="" />
          </div>
          <div class="col-md-5 wow fadeInRight" data-wow-delay=".5s">
            <h3>Documents and Knowledge Management 


</h3>
            <p>
Allow your organizational users to upload new files and collect important files from projects and tasks in understandable categories and share whatever they want at different levels of users, teams or organizational departments.

            </p>
            
          </div>
        </div>

      </div>
    </section>


       <section class="no-top" v-if="$t('lang') == 'ar'">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-5 wow fadeInRight" data-wow-delay=".5s">
            <h3>إدارة الاستراتيجية 

</h3>
            <p>
نظام مبتكر لإدارة تنفيذ الاستراتيجية يعمل على تمكين فرق العمل من اتخاذ الإجراءات والشعور بتبني أهداف المنظمة الاستراتيجية ومبادراتها ومشاريعها. النظام باختصار يمنح في الوقت الفعلي رؤية لتقييم الإنجازات نحو تحقيق الأهداف الاستراتيجية. ويتيح إضافة عناصر الخطة الاستراتيجية وربطها بالأهداف التنفيذية والمبادرات والمشاريع والمهام وفقًا لخارطة استراتيجية سهلة وواضحة. ضمن تسلسل صحيح يعرض مؤشرات الأداء التي تقارن القيم المستهدفة بالإنجازات الفعلية.

            </p>
            
          </div>
          <div class="col-md-7 wow fadeInLeft" data-wow-delay=".5s">
            <img src="/assets/images/screens/screens-ar-1.jpg" class="img-fluid" alt="" />
          </div>
        </div>

         <div class="row align-items-center">
             <div class="col-md-7 wow fadeInLeft" data-wow-delay=".5s">
            <img src="/assets/images/screens/screens-ar-2.jpg" class="img-fluid" alt="" />
          </div>
          <div class="col-md-5 wow fadeInRight" data-wow-delay=".5s">
            <h3>
              الإدارة المؤسسية للمشاريع


</h3>
            <p>
يمكن النظام مديري المشاريع من تقديم نتائج عالية الجودة للمشروع باستمرار، لتبقي القادة التنفيذيين دائما على دراية بتفاصيل وحالة مشاريع المنظمة. كما تؤكد مواءمة محافظ المشاريع المؤسسية مع الأهداف الاستراتيجية، وأن تسليم المشاريع يتم بنجاح وفقا لخططها. 

            </p>
            
          </div>
        
        </div>

        <div class="spacer-double"></div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-11 text-center wow fadeInUp" data-wow-delay=".5s">
            <h3>
منهجية إدارة المشروع              <br />
                           منهجية في نظام

            </h3>

            <img src="/assets/images/diagrams/1-ar.png" class="img-fluid" alt="" />
          </div>
        </div>


       <div class="row align-items-center">
          <div class="col-md-5 wow fadeInRight" data-wow-delay=".5s">
            <h3>إدارة المهـــــام 


</h3>
            <p>
يمكن هذا النظام الموظفين بكافة مستوياتهم من إدارة المهام بشكل تعاوني تفاعلي وفقا للتسلسل الإداري، كما يساعد على الانضباط في تنفيذ الأعمال حسب الوقت والجودة المطلوبة، تم تصميم النظام بعدة خيارات لعرض المهام، كما تم ربط كافة المهام بمؤشرات سرعة الاستجابة وكذلك قياس الجودة والإنتاجية على مستوى الموظف والفريق والإدارة والمنظمة. 

            </p>
            
          </div>
          <div class="col-md-7 wow fadeInLeft" data-wow-delay=".5s">
            <img src="/assets/images/screens/screens-ar-3.jpg" class="img-fluid" alt="" />
          </div>
        </div>



       <div class="row align-items-center">
         
          <div class="col-md-7 wow fadeInLeft" data-wow-delay=".5s">
            <img src="/assets/images/screens/screens-ar-4.jpg" class="img-fluid" alt="" />
          </div>
          <div class="col-md-5 wow fadeInRight" data-wow-delay=".5s">
            <h3>إدارة المعرفة والوثائق 



</h3>
            <p>
يتيح النظام تجميع الوثائق المرتبطة بالمشاريع أو المهام أو رفع ملفات أخرى جديدة وترتيبها وفقا لتصنيفات مناسبة مع إمكانية مشاركة بعضها إما بشكل عام أو على مستوى الإدارة أو المستخدمين. 

            </p>
            
          </div>
        </div>

      </div>
    </section>


  </div>
  <!-- content close -->
</template>

<script>
// import $ from "jquery";

import WOW from "wowjs";
// import testimonial from '@/components/home/testimonial.vue';
export default {
  name: "Home",
  data() {
    return {
      checked: false,
    };
  },
  components: {},
  methods: {},
  created: function () {},
  mounted() {
    new WOW.WOW().init();
    this.$parent.custom_bg();
  },
};
</script>
