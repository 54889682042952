<template>
  <div>
    <!-- content begin -->
    <div class="no-bottom no-top" id="content">
      <div id="top"></div>

      <!-- section begin -->
      <section id="subheader">
        <div class="center-y relative text-center" data-scroll-speed="4">
          <div class="container">
            <div class="row mt-4">
              <div class="col-md-8 offset-md-2">
                <div class="row" v-if="$t('lang') == 'en'">
                  <div class="col-md-12 text-center">
                    <h1>Contact Us</h1>
                    <p>It is a pleasure to contact you</p>
                  </div>
                  <div class="clearfix"></div>
                </div>

                <div class="row" v-if="$t('lang') == 'ar'">
                  <div class="col-md-12 text-center">
                    <h1>اتصل بنا</h1>
                    <p>يسعدنا ان نتواصل معك</p>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- section close -->

      <section
        style="
          background: url('/Theme/images/background/3d.png') center top / cover;
        "
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-7 wow fadeInLeft" data-wow-delay=".5s">
              <h3>{{ $t("questions?") }}</h3>

              <div class="form-border">
                <div class="field-set">
                  <input
                    v-model="Name"
                    type="text"
                    name="name"
                    id="name"
                    class="form-control"
                    :placeholder="$t('Name')"
                  />
                </div>

                <div class="field-set">
                  <input
                    v-model="email"
                    type="text"
                    name="email"
                    id="email"
                    class="form-control"
                    :placeholder="$t('Email')"
                  />
                </div>

                <div
                  class="field-set mb-3"
                  style="direction: ltr; text-align: left"
                >
                  <input
                    v-model="phone"
                    type="text"
                    name="phone"
                    id="phone"
                    class="form-control"
                    style="width: 100%"
                  />
                </div>

                <div class="field-set">
                  <textarea
                    v-model="Message"
                    style="height: 200px"
                    name="message"
                    id="message"
                    class="form-control"
                    :placeholder="$t('Message')"
                  ></textarea>
                </div>

                <div class="spacer-half"></div>

                <div id="submit">
                  <input
                    @click="SendEmail()"
                    type="submit"
                    id="send_message"
                    :value="$t('Send')"
                    class="btn btn-custom"
                  />
                </div>
                <div id="mail_success" class="success">
                  {{ $t("successfully") }}
                </div>
                <div id="mail_fail" class="error">
                  {{ $t("Error") }}
                </div>
              </div>
            </div>
            <div class="col-md-5 wow fadeInLeft" data-wow-delay=".5s">
              <img src="/Theme/images/misc/2.png" class="img-fluid" alt="" />
            </div>

            <div class="spacer-double"></div>
          </div>
          <div
            class="col-lg-12 wow fadeInLeft text-center"
            data-wow-delay=".5s"
          >
            <p v-if="$t('lang') == 'en'" class="center-p">
              <span class="id-color">(!) </span>
              We will handle your personal data based on our Privacy Policy, to
              answer your question about our products and services.
            </p>
            <p v-if="$t('lang') == 'ar'"  class="center-p">
              <span class="id-color">(!) </span>
              سوف نتعامل مع بياناتك وفقا لاتفاقية الخصوصية فقط للإجابة على
              استفساراتك وتقديم التوضيحات اللازمة حول منتجاتنا وحلولنا
            </p>
          </div>
        </div>
      </section>
    </div>
    <preFooter></preFooter>
  </div>
</template>

<script>
// import $ from "jquery";

import WOW from "wowjs";
import axios from "axios";
import preFooter from "@/components/home/preFooter.vue";
export default {
  name: "Home",
  data() {
    return {
      Name: null,
      phone: null,
      email: null,
      Message: null,
      checked: false,
    };
  },
  components: {
    // PricingTable,
    // testimonial,
    preFooter,
  },
  methods: {
    SendEmail() {
      if (this.Name == null) {
        this.$toasted.show("Please Enter your Name", {
          fullWidth: "fullWidth",
          type: "error",
          theme: "bubble",
          position: "top-center",
          duration: 2000,
        });

        return false;
      } else if (this.phone == null) {
        this.$toasted.show("Please Enter your Phone", {
          fullWidth: "fullWidth",
          type: "error",
          theme: "bubble",
          position: "top-center",
          duration: 2000,
        });

        return false;
      } else if (this.email == null) {
        this.$toasted.show("Please Enter your Email", {
          fullWidth: "fullWidth",
          type: "error",
          theme: "bubble",
          position: "top-center",
          duration: 2000,
        });

        return false;
      } else if (this.email == null) {
        this.$toasted.show("Please Enter your Email", {
          fullWidth: "fullWidth",
          type: "error",
          theme: "bubble",
          position: "top-center",
          duration: 2000,
        });

        return false;
      } else if (
        this.email.indexOf("@") == -1 ||
        this.email.indexOf(".") == -1
      ) {
        this.$toasted.show("Please Enter Valid Email", {
          fullWidth: "fullWidth",
          type: "error",
          theme: "bubble",
          position: "top-center",
          duration: 2000,
        });

        return false;
      } else if (this.Message == null) {
        this.$toasted.show("Please Enter Your Message", {
          fullWidth: "fullWidth",
          type: "error",
          theme: "bubble",
          position: "top-center",
          duration: 2000,
        });

        return false;
      } else {
        axios.get(
          "https://checkaccount.p2capp.com/Accounts/SendEmail?Name=" +
            this.Name +
            "&phone=" +
            this.phone +
            "&Email=" +
            this.email +
            "&message" +
            this.Message
        );

        this.$toasted.show("Message Sent Successfully", {
          fullWidth: "fullWidth",
          type: "success",
          theme: "bubble",
          position: "top-center",
          duration: 2000,
        });
        this.Name = null;
        this.phone = null;
        this.email = null;
        this.Message = null;
      }
    },
    // functionName: function () {

    // },
  },
  created: function () {},
  mounted() {
    new WOW.WOW().init();
    this.$parent.custom_bg();

    var input = document.querySelector("#phone");
    window.intlTelInput(input, {
      // allowDropdown: false,
      // autoHideDialCode: false,
      // autoPlaceholder: "off",
      // dropdownContainer: document.body,
      // excludeCountries: ["us"],
      // formatOnDisplay: false,
      // geoIpLookup: function(callback) {
      //   $.get("http://ipinfo.io", function() {}, "jsonp").always(function(resp) {
      //     var countryCode = (resp && resp.country) ? resp.country : "";
      //     callback(countryCode);
      //   });
      // },
      // hiddenInput: "full_number",
       initialCountry: "sa",
      // localizedCountries: { 'de': 'Deutschland' },
      // nationalMode: false,
      // onlyCountries: ['us', 'gb', 'ch', 'ca', 'do'], 
       preferredCountries: ['sa', 'ae','KW', 'om', 'qa', 'bh', 'eg'],
       separateDialCode: true,
      utilsScript: "/lib/intl-tel-input-master/js/utils.js",
    });
  },
};
</script>
