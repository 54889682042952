<template>
  <div class="container pt-4">
    <h1>This is an about page</h1>
    <HelloWorld></HelloWorld>
  </div>
</template>

<script>
import $ from "jquery";
import HelloWorld from '@/components/HelloWorld.vue';

export default {
  name: "registration",
  data() {
    return { 
      checked: false 
    };
  },
  components: { 
    HelloWorld 
    },
  methods: {
    functionName: function() {
   
    }
  },
  created: function() {},
  mounted() {
        $('h1:last').text('jquery Runing')
  }
};
</script>