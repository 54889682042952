<template>
  <!-- content begin -->
  <div class="no-bottom no-top" id="content">
    <div id="top"></div>

    <!-- section begin -->
    <section
      id="subheader"
      v-if="$t('lang') == 'en'"
      data-bgimage="url(images/background/5.png) bottom"
    >
      <div class="center-y relative text-center" data-scroll-speed="4">
        <div class="container">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <form
                action="blank.php"
                class="row"
                id="form_subscribe"
                method="post"
                name="myForm"
              >
                <div class="col text-center">
                  <div class="spacer-single"></div>
                  <h1>About Us</h1>
                  <p>The Innovative Choice For Strategy</p>
                </div>
                <div class="clearfix"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- section close -->
    <!-- section begin -->
    <section
      id="subheader"
      v-if="$t('lang') == 'ar'"
      data-bgimage="url(images/background/5.png) bottom"
    >
      <div class="center-y relative text-center" data-scroll-speed="4">
        <div class="container">
          <div class="row">
            <div class="col-md-8 offset-md-2">
              <form
                action="blank.php"
                class="row"
                id="form_subscribe"
                method="post"
                name="myForm"
              >
                <div class="col text-center">
                  <div class="spacer-single"></div>
                  <h1>من نكون</h1>
                  <p>استشارات عالية الجودة، مع أحدث الحلول المبتكرة</p>
                </div>
                <div class="clearfix"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- section close -->
    <section
      class="no-top"
      v-if="$t('lang') == 'en'"
      data-bgimage="url(/Theme/images/background/3d.png) center"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-5 wow fadeInLeft" data-wow-delay=".5s">
            <img src="/Theme/images/misc/6.png" class="img-fluid" alt="" />
          </div>
          <div
            class="col-md-6 offset-md-1 wow fadeInRight"
            data-wow-delay=".5s"
          >
            <h3>What makes us different?</h3>
            <p>
              P2C solutions are Trademark and Copyright registered to iEPM
              company and operated by it. iEPM JSC company was established in
              2012 in Riyadh, and it aims to provide high quality consulting
              with innovative solutions in strategy, portfolio project and tasks
              management. iEPM care about all details of our clients’ needs for
              an effective and productive digital transformation.
            </p>
            <p>
              We work with our clients based on win-win partnership relations.
              This principle ensures our partnerships continue, and the trust
              between us strengthens. iEPM provides high quality consulting and
              solutions in business strategy, project portfolio management,
              digital transformation, and innovative technology. Nowadays, we
              offer a full range of integrated comprehensive services and
              solutions for the government and private sectors from all
              industries including Finance, Education, Healthcare, Logistics,
              Food & Beverage, Retail Shops, Technology, Energy, Hospitality,
              Telecommunications, Transportation, Oil & Gas, and Construction &
              Maintenance.
            </p>
          </div>
        </div>

        <div class="spacer-double"></div>

        <div class="row align-items-center">
          <div class="col-md-6 wow fadeInLeft" data-wow-delay=".5s">
            <h3>Our Vision</h3>
            <p>
              To be a world-class leader in organizational strategy and project
              portfolio management, consulting and solutions provision.
            </p>
            <h3>Our Mission</h3>
            <p>
              Through its distinguished staff, iEPM is keen to provide quality
              services featuring the latest and most effective innovations,
              whilst also focusing on customer objectives and requirements. By
              increasing work efficiency and productivity, reducing costs, and
              minimising risks, we achieve success for our projects and enhanced
              customer satisfaction.
            </p>
            <h3>Our Values</h3>
            <ul>
              <li>Unified team spirit</li>
              <li>Transparency and authenticity</li>
              <li>Integrity and confidentiality</li>
              <li>Abiding by work ethics</li>
              <li>Abiding by quality standards</li>
              <li>Fulfilling time and cost commitments</li>
            </ul>
          </div>

          <div class="col-md-5 offset-md-1 wow fadeInLeft" data-wow-delay=".5s">
            <img src="/Theme/images/misc/3.png" class="img-fluid" alt="" />
          </div>
        </div>

        <div class="spacer-double"></div>
        <div class="row justify-content-center bd-highlight mb-3">
          <div class="col-md-2">
            <a href="https://iepm.sa/" target="_blank">
              <img src="/assets/images/iepm.png" class="img-fluid" alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>


      <section
      class="no-top"
      v-if="$t('lang') == 'ar'"
      data-bgimage="url(/Theme/images/background/3d.png) center"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-5 wow fadeInLeft" data-wow-delay=".5s">
            <img src="/Theme/images/misc/6.png" class="img-fluid" alt="" />
          </div>
          <div
            class="col-md-6 offset-md-1 wow fadeInRight"
            data-wow-delay=".5s"
          >
            <h3>استشارات عالية الجودة، مع أحدث الحلول المبتكرة</h3>
            <p>

              P2C 
              هي علامة تجارية وحقوق نشر مسجلة لدى
               شركة البيئة المتكاملة لإدارة المشاريع (iEPM) هي شركة مساهمة مقفلة، تأسست عام 2012 في الرياض المملكة العربية السعودية وهي تهدف إلى تقديم استشارات عالية الجودة مع حلول مبتكرة في إدارة الاستراتيجيات ومحافظ المشاريع، تهتم الشركة بجميع تفاصيل احتياجات عملائها من أجل تحول رقمي فعال ومنتج في كل نواحي العمل.

 
            
            </p>
            <p>
         نحن في شركة (iEPM) نعمل مع عملائنا على أساس علاقة الشراكة المربحة للجانبين. إذ يضمن هذا المبدأ استمرار شراكاتنا وتقوية الثقة بيننا وبين عملائنا

تقدم الشركة للقطاعين الحكومي والخاص مجموعة كاملة من خدمات الاستشارات والحلول التقنية عالية الجودة في استراتيجيات الأعمال وإدارة محافظ المشاريع والتحول الرقمي والتكنولوجيا المبتكرة في هذه المجالات، تشمل خدماتنا جميع الصناعات بما في ذلك المالية والتعليم والرعاية الصحية والخدمات اللوجستية والأغذية ومحلات البيع بالتجزئة والتكنولوجيا والطاقة والضيافة والاتصالات والنقل والنفط والغاز والبناء والصيانة


            </p>
          </div>
        </div>

        <div class="spacer-double"></div>

        <div class="row align-items-center">
          <div class="col-md-6 wow fadeInLeft" data-wow-delay=".5s">
            <h3>رؤيتنـــــا
</h3>
            <p>
           أن تكون الشركة رائدة عالميًا في استشارات وحلول إدارة الاستراتيجيات المؤسسية وإدارة محافظ المشاريع وتطوير الأعمال.

            </p>
            <h3>رسالتنــا</h3>
            <p>
          الالتزام بتقديم خدمات عالية الجودة تتميز بأحدث الابتكارات وأكثرها فعالية، مع التركيز أيضًا على أهداف العملاء ومتطلباتهم، من خلال زيادة كفاءة العمل والإنتاجية، وخفض التكاليف، وتقليل المخاطر، وبهذا نحقق النجاح لمشاريعنا ونعزز رضا العملاء حول خدماتنا وحلولنا.

            </p>
            <h3> قيمنا </h3>
            <ul>
              <li> روح الفريق الموحدة </li>
              <li> الشفافية والأصالة </li>
              <li> النزاهة والسرية </li>
              <li> الالتزام بأخلاقيات العمل </li>
              <li> الالتزام بمعايير الجودة </li>
              <li> الوفاء بالتزامات الوقت والتكلفة </li>
            </ul>
          </div>

          <div class="col-md-5 offset-md-1 wow fadeInLeft" data-wow-delay=".5s">
            <img src="/Theme/images/misc/3.png" class="img-fluid" alt="" />
          </div>
        </div>

        <div class="spacer-double"></div>
        <div class="row justify-content-center bd-highlight mb-3">
          <div class="col-md-2">
            <a href="https://iepm.sa/" target="_blank">
              <img src="/assets/images/iepm.png" class="img-fluid" alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>


  </div>
  <!-- content close -->
</template>

<script>
// import $ from "jquery";

import WOW from "wowjs";
// import testimonial from '@/components/home/testimonial.vue';
export default {
  name: "Home",
  data() {
    return {
      checked: false,
    };
  },
  components: {},
  methods: {},
  created: function () {},
  mounted() {
    new WOW.WOW().init();
    this.$parent.custom_bg();
  },
};
</script>
