<template>
  <div>
    <section id="section-hero" class="full-height vertical-center">
      <div class="container">
        <div class="row align-items-center">
          <div
            v-if="$t('lang') == 'en'"
            class="col-lg-5 wow fadeInRight"
            data-wow-delay=".5s"
          >
            <!-- <h4 class="id-color">Process To Complete</h4> -->
            <div class="spacer-10" style="background-size: cover"></div>
            <h1>
             <span class="id-color" style="font-size: 50px">Innovative solutions</span>
             <br/>
             <span   style="font-size: 32px">
               Next level quality in Strategy, Projects and tasks Management. 
             </span> 
            </h1>
            <p class="lead">
               P2C is empower teams to collaborate efficiently to achieve business strategic goals.
            </p>
            <div class="spacer-20" style="background-size: cover"></div>
            <router-link class="btn-custom mr-2" :to="'/'+$t('lang')+'/Solutions'"
              >Read More</router-link
            >
            <router-link class="btn-custom bg-color-2" :to="'/'+$t('lang')+'/Pricing'"
              >Start</router-link
            >

            <div class="mb-sm-30" style="background-size: cover"></div>
          </div>
          <div
            v-if="$t('lang') == 'ar'"
            class="col-lg-5 wow fadeInRight"
            data-wow-delay=".5s"
          >
            <!-- <h4 class="id-color">Process To Complete</h4> -->
            <div class="spacer-10" style="background-size: cover"></div>
           <h1>
             <span class="id-color" style="font-size: 50px">حلولنا المبتكرة</span>
             <br/>
             <span   style="font-size: 32px">
              جودة تتخطى الزمن
في إدارة الاستراتيجيات والمشاريع والمهام.

             </span> 
            </h1>
            <p class="lead">
          حلول بيتوسي (P2C) لتمكين فرق العمل من التعاون بفاعلية أكبر لتحقيق الأهداف الاستراتيجية
            </p>
            <div class="spacer-20" style="background-size: cover"></div>
            <router-link class="btn-custom mr-2" :to="'/'+$t('lang')+'/Solutions'"
              >اقرأ المزيد ..</router-link
            >
            <router-link class="btn-custom bg-color-2" :to="'/'+$t('lang')+'/Pricing'"
              >ابدأ</router-link
            >

            <div class="mb-sm-30" style="background-size: cover"></div>
          </div>
          <div class="col-lg-7 wow fadeInLeft" data-wow-delay=".5s">
            <img
              src="/assets/images/diagrams/2.png"
              v-if="$t('lang') == 'en'"
              class="img-fluid"
              alt=""
            />
            <img
              src="/assets/images/diagrams/2-ar.png"
              v-if="$t('lang') == 'ar'"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

    <section
      id="section-highlight"
      class="no-top"
      data-bgimage="url(/Theme/images/background/3b.png) top"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <div class="text-center" v-if="$t('lang') == 'en'">
              <h2>
                <span class="uptitle id-color">System Features </span>Achieve
                Your Business Strategic Goals
              </h2>
              <div class="spacer-20"></div>
            </div>

            <div class="text-center" v-if="$t('lang') == 'ar'">
              <h2>
                <span class="uptitle id-color">مزايا النظام </span>حقق أهداف
                عملك الاستراتيجية
              </h2>
              <div class="spacer-20"></div>
            </div>
          </div>
        </div>

        <div class="row" v-if="$t('lang') == 'en'">
          <div
            class="col-lg-4 col-md-6 mb30 wow fadeInRight"
            data-wow-delay="0s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_03.png"
                  class="feature-img"
                />
              </div>
              <div class="fb-text">
                <h4>Cloud-Based System</h4>
                <p>
                  P2C is a cloud-based system giving real time visibility to
                  evaluate achievements in order to reach the business strategic
                  objectives.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6 mb30 wow fadeInRight"
            data-wow-delay=".25s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_05.png"
                  class="feature-img"
                />
              </div>
              <div class="fb-text">
                <h4>Integration</h4>
                <p>
                  P2C is designed to integrate with all business essential
                  systems, such as ERP and EPM, through fast APIs.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6 mb30 wow fadeInRight"
            data-wow-delay=".5s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_09.png"
                  class="feature-img"
                />
              </div>
              <div class="fb-text">
                <h4>Easy user experience</h4>
                <p>
                  Your team will be confident when using the P2C platform in
                  both Web and mobile software with easy to navigate pages...
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6 mb30 wow fadeInRight"
            data-wow-delay="0s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_10.png"
                  class="feature-img"
                />
              </div>
              <div class="fb-text">
                <h4>Organizational Chart</h4>
                <p>
                  Build your own organizational chart with allocated teams and
                  employees, or import it from your ERP system. By default,
                  authority, workflow and task management...
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6 mb30 wow fadeInRight"
            data-wow-delay=".25s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_13.png"
                  class="feature-img"
                />
              </div>
              <div class="fb-text">
                <h4>Multi-language support</h4>
                <p>
                  P2C comes by default with Arabic and English languages
                  support, but other languages can be easily added as needed.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-lg-4 col-md-6 mb30 wow fadeInRight"
            data-wow-delay=".5s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_14.png"
                  class="feature-img"
                />
              </div>
              <div class="fb-text">
                <h4>Workflows</h4>
                <p>
                  Go fast with simple and powerful workflow; adopt P2C ready
                  to-use workflows, customize them, or create your own to
                  automate your processes.
                </p>
              </div>
            </div>
          </div>
          <router-link :to="'/'+$t('lang')+'/Features'" class="btn-custom m-auto"
            >Read More</router-link
          >
        </div>

        <div class="row" v-if="$t('lang') == 'ar'">
          <div
            class="col-lg-6 col-md-6 mb30 wow fadeInRight"
            data-wow-delay="0s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_03.png"
                  class="feature-img"
                />
              </div>
              <div class="fb-text">
                <h4>منصة عمل سحابية</h4>
                <p>
                  نظام (P2C) هو منصة عمل سحابية تمنحك في الوقت الفعلي رؤية
                  لتقييم إنجازات منظمتك نحو تحقيق أهدافها الاستراتيجية..
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-lg-6 col-md-6 mb30 wow fadeInRight"
            data-wow-delay=".25s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_05.png"
                  class="feature-img"
                />
              </div>
              <div class="fb-text">
                <h4>التكامل</h4>
                <p>
                  تم تصميم منصة (P2C) للتكامل عند الحاجة مع نظم تخطيط الموارد
                  (ERP) ونظم إدارة المشاريع الأخرى (EPM)
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-lg-6 col-md-6 mb30 wow fadeInRight"
            data-wow-delay=".5s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_09.png"
                  class="feature-img"
                />
              </div>
              <div class="fb-text">
                <h4>تجربة مستخدم سهلة وممتعة</h4>
                <p>
                  منصة سهلة الفهم والاستخدام وذات شكل جذاب يجعل من فريق العمل
                  واثقا ومستمتعا عند استخدامها والتنقل خلالها، والاستفادة من
                  نظام التنبيهات ومؤشرات الأداء الشخصية..
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-lg-6 col-md-6 mb30 wow fadeInRight"
            data-wow-delay="0s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_10.png"
                  class="feature-img"
                />
              </div>
              <div class="fb-text">
                <h4>أداة الهيكل التنظيمي (Organizational Chart)</h4>
                <p>
                  يمكن بناء الهيكل التنظيمي للمنظمة واسكان الموظفين في الإدارات
                  والأقسام والفروع المختلفة أو في لجان العمل، كما يمكن ربط
                  واستيراد بيانات الموظفين مباشرة من نظام (ERP)، بشكل افتراضي
                  الكثير من الصلاحيات وتدفق العمل تسير وفقا للتسلسل الوظيفي في
                  هذا الهيكل.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-lg-6 col-md-6 mb30 wow fadeInRight"
            data-wow-delay=".25s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_13.png"
                  class="feature-img"
                />
              </div>
              <div class="fb-text">
                <h4>دعم عدة لغات</h4>
                <p>
                  بشكل افتراضي تدعم المنصة اللغة العربية والانجليزية، مع قابلية
                  إضافة العديد من اللغات الأخرى عند احتياج عميل المنصة.
                </p>
              </div>
            </div>
          </div>

          <div
            class="col-lg-6 col-md-6 mb30 wow fadeInRight"
            data-wow-delay=".5s"
          >
            <div class="f-box f-icon-left f-icon-rounded">
              <div>
                <img
                  src="/Theme/images/fe-icons/icons_14.png"
                  class="feature-img"
                />
              </div>
              <div class="fb-text">
                <h4>أداة تدفق العمل (Workflows)</h4>
                <p>
                  يمكن الانطلاق بشكل أسرع مع الإجراءات البسيطة المعرفة سابقا،
                  كما يتيح نظام تدفق العمل بشكل سهل وقوي تعديل مسارات تلك
                  الإجراءات وتغيير أزمنة الأنشطة والمسؤولين عن تنفيذها سواء كانو
                  مستخدمين أو إدارات، كما تمنح مؤشرات قياس الأداء التشغيلية
                  تقييم أداء هذه الإجراءات لغرض التحسين المستمر..
                </p>
              </div>
            </div>
          </div>
           <router-link :to="'/'+$t('lang')+'/Features'" class="btn-custom m-auto text-center"
            >اقرأ المزيد</router-link
          >

        </div>
      </div>
    </section>

    <!-- section begin -->
    <!-- <screenshots></screenshots> -->
    <!-- section close -->
    <section id="section-text" class="no-top">
      <div class="container">
        <div class="row align-items-center">
          <div
            class="col-lg-5 offset-md-1 wow fadeInLeft"
            data-wow-delay="0s"
            v-if="$t('lang') == 'en'"
          >
            <h2>Tasks Management</h2>
            <p class="lead">
              Embrace P2C advance tasks management with confidence! It is a
              faster and easier way to manage all the organization tasks in
              various stages of a process, with KPIs to measure the responsive
              rate, quality and productivity at all different levels. All tasks
              can be represented in three viewing options: visual task Boards
              (Kanban), tasks list, and visual task Gantt-chart on the calendar.
              <!-- The Kanban view allows you to rapidly, by drag‑and‑drop, change a task’s state, change its progress, or add reminders. -->
              You can assign individual or teams’ tasks, and also attach files
              or threads of comments and discussions.
            </p>
            <div class="spacer-half"></div>
            <router-link class="btn-custom" :to="'/'+$t('lang')+'/Pricing'"
              >Get Started</router-link
            >
          </div>
          <div
            class="col-lg-5 offset-md-1 wow fadeInLeft"
            data-wow-delay="0s"
            v-if="$t('lang') == 'ar'"
          >
            <h2>إدارة المهـــــام</h2>
            <p class="lead">
              يمكن هذا النظام الموظفين بكافة مستوياتهم من إدارة المهام بشكل
              تعاوني تفاعلي وفقا للتسلسل الإداري، كما يساعد على الانضباط في
              تنفيذ الأعمال حسب الوقت والجودة المطلوبة، تم تصميم النظام بعدة
              خيارات لعرض المهام، كما تم ربط كافة المهام بمؤشرات سرعة الاستجابة
              وكذلك قياس الجودة والإنتاجية على مستوى الموظف والفريق والإدارة
              والمنظمة.
            </p>
            <div class="spacer-half"></div>
            <router-link class="btn-custom" :to="'/'+$t('lang')+'/Pricing'"
              >ابدأ الأن</router-link
            >
          </div>
          <div
            class="col-lg-6 d-none d-lg-block d-xl-block text-center wow fadeInRight"
            data-wow-delay="0s"
          >
            <img
              class="relative img-fluid"
              src="/assets/images/home/1.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

    <section id="section-text" class="no-top">
      <div class="container">
        <div class="row align-items-center">
          <div
            class="col-lg-6 d-none d-lg-block d-xl-block text-center wow fadeInRight"
            data-wow-delay="0s"
          >
            <img
              class="relative img-fluid"
              src="/assets/images/home/2.jpg"
              alt=""
            />
          </div>

          <div
            class="col-lg-5 offset-md-1 wow fadeInLeft"
            data-wow-delay="0s"
            v-if="$t('lang') == 'en'">
            <h2>Strategic and Operation Management</h2>
            <p class="lead">
              P2C allows adding and linking the strategic plan elements with the
              execution objectives, KPIs, initiatives, projects and tasks
              according to a clear strategic map. The strategic map sequence
              sends values to the KPIs to show progress and performance and
              compare targets with actual achievements.
            </p>
            <div class="spacer-half"></div>
            <router-link class="btn-custom" :to="'/'+$t('lang')+'/Pricing'"
              >Get Started</router-link
            >
          </div>

          <div
            class="col-lg-5 offset-md-1 wow fadeInLeft"
            data-wow-delay="0s"
            v-if="$t('lang') == 'ar'"
          >
            <h2>إدارة الاستراتيجية</h2>
            <p class="lead">
              نظام مبتكر لإدارة تنفيذ الاستراتيجية يعمل على تمكين فرق العمل من
              اتخاذ الإجراءات والشعور بتبني أهداف المنظمة الاستراتيجية
              ومبادراتها ومشاريعها. النظام باختصار يمنح في الوقت الفعلي رؤية
              لتقييم الإنجازات نحو تحقيق الأهداف الاستراتيجية. ويتيح إضافة عناصر
              الخطة الاستراتيجية وربطها بالأهداف التنفيذية والمبادرات والمشاريع
              والمهام وفقًا لخارطة استراتيجية سهلة وواضحة. ضمن تسلسل صحيح يعرض
              مؤشرات الأداء التي تقارن القيم المستهدفة بالإنجازات الفعلية.
            </p>
            <div class="spacer-half"></div>
            <router-link class="btn-custom" :to="'/'+$t('lang')+'/Pricing'"
              >ابدأ الأن</router-link
            >
          </div>
        </div>
      </div>
    </section>

    <section
      class=" "
      style="background-size: cover; background-color: #f5f9fd"
    >
      <div class="container" style="background-size: cover">
        <h4 class="id-color text-center" v-if="$t('lang') == 'en'">
          Download mobile apps Now !
        </h4>
        <h4 class="id-color text-center" v-if="$t('lang') == 'ar'">
          حمل تطبيق الجوال الأن !
        </h4>
        <div class="row" style="background-size: cover">
          <div class="col-md-12 text-center" style="background-size: cover">
            <a
              href="https://apps.apple.com/us/app/p2c-app/id1545825369"
              target="_blank" 
              ><img
                src="/Theme/images/misc/download-appstore.png"
                class="img-fluid pb-2" /></a
            >&nbsp;
            <a
              href="https://play.google.com/store/apps/details?id=com.iepm.p2c"
              target="_blank" 
              ><img
                src="/Theme/images/misc/download-playstore.png"
                class="img-fluid pb-2"
            /></a>
          </div>
        </div>
      </div>
    </section>
    <!-- <testimonial></testimonial> -->
    <preFooter></preFooter>
  </div>
</template>

<script>
// import $ from "jquery";

import WOW from "wowjs";
// import testimonial from '@/components/home/testimonial.vue';
import preFooter from "@/components/home/preFooter.vue";
// import screenshots from '@/components/home/screenshots.vue';
export default {
  name: "Home",
  data() {
    return {
      checked: false,
    };
  },
  components: {
    // testimonial,
    // screenshots,
    preFooter,
  },
  methods: {
    functionName: function () {},
  },
  created: function () {},
  mounted() {
    new WOW.WOW().init();
    this.$parent.custom_bg();
  },
};
</script>
